import './App.css';
import './font.scss'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CurrentAddress from '../../helpers/current-location'
import Header from '../Home/header'
import Home from '../Home/home'
import About from '../Home/about'
import Work from '../Home/work';
import Contact from '../Home/contact'
import Login from '../Auth/login'
import Signup from '../Auth/signup';
import ForgotPassword from '../Auth/forgotPassword';
import Search from '../Dashboard/search';
import Dashboard from '../Dashboard/dashboard';
import Main from '../../helpers/route-change'
import LogOut from '../Auth/logout';
import Details from '../Dashboard/parking-details';
import Summary from '../Dashboard/summary';
import Profile from '../Dashboard/profile';
import ResetPassword from '../Auth/resetPassword';
function App() {
  const menuItems = [
    {
      route: 'Home',
      link: './home'
    },
    {
      route: 'About us',
      link: './about'
    },
    {
      route: 'How it works',
      link: './work'
    },
    {
      route: 'Contact us',
      link: './contact'
    } ,
    {
        route: localStorage.getItem('token') ? 'Dashboard' : 'Login',
        link: localStorage.getItem('token')? './dashboard' : './login'
    }
  ]

  const navItems = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/home",
      element: <Home />
    },
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/work",
      element: <Work />
    },
    {
      path: "/contact",
      element: <Contact />
    },
    {
      path: "/signup",
      element: <Signup />
    },
    {
      path: "/login",
      element: <Login navItem={menuItems} />
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword/>
    }, {
      path: "/resetpassword/:user_id/:token",
      element: <ResetPassword/>
    },
    {
      path: "/search",
      element: <Search />
    },
    {
      path: "/dashboard",
      element: <Dashboard />
    },
    {
      path: "/details",
      element: <Details />
    },
    {
      path: "/summary",
      element: <Summary />
    },
    {
      path: "/profile",
      element: <Profile />
    }
    ,
    {
      path: "/logout",
      element: <LogOut />
    }
  ]
  return (
    <div className="App">
      <Router>
        <Main>
          <CurrentAddress />
          <Header key={menuItems.route} menus={menuItems} />
          <div className="container">
            <Routes>
              {navItems.map((item) => (
                <Route key={item.path} path={item.path} element={item.element} />
              ))}
            </Routes>
          </div>
        </Main>
      </Router>
    </div>
  );
}

export default App;
