import React from 'react';
import '../../Styles/global.scss'
import { Box, Typography } from "@mui/material";
import maintain from '../../Images/web-maintenance.png'

export default function Work() {
    const Work = (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem', fontSize: '48px' }}>
            <h2 style={{ color: '#bd3f32' }}>COMING  <span style={{ color: '#0099ff' }}> SOON...</span></h2>
            <img src={maintain} loading="lazy" alt='logo' style={{ width: '500px', height: '100%' }} />
        </Box>
    );
    return (
        <Box>{Work}</Box>
    );
}
