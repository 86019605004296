import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../../Styles/cards.scss'
import { Box, Card, CardContent, Tabs, Tab, Button, Rating, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, CircularProgress } from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import { environment } from '../../environment/environment';
import loader from '../../Images/loading.gif'
import calendar from '../../Images/cancel-calendar.png'
import axios from 'axios';
import moment from 'moment/moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function HistoryParking() {
    const [bookParkingHistory, setBookParkingHistory] = useState([]);
    const [ownerBookingHistory, setOwnerBookingHistory] = useState([]);
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [ownerpage, setOwnerPage] = useState(0);
    const [rowsPerOwnerPage, setRowsPerOwnerPage] = useState(3);
    const [load, setLoad] = useState(true);
    const [deletedata, setDeleteData] = useState([]);
    const [open, setOpen] = useState(false);
    const [btnload, setbtnLoad] = useState(false);
    const navigate = useNavigate();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        BookParkingHistory()
        OwnerBookingHistory()
        setLoad(true)
    }, []);

    const redirectToDetails = (item) => {
        localStorage.setItem('myData', JSON.stringify(item));
        localStorage.setItem('fromHistroy', true);
        localStorage.removeItem('fromMyParking')
        navigate('/details')
    }

    const BookParkingHistory = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: localStorage.getItem('userId')
            }
            await axios.post(API_URL + 'bookParkingHistory', requestBody, { headers: header }).then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data)
                    setTimeout(() => {
                        setLoad(false)
                    }, 1000);
                    setBookParkingHistory(response.data.data)
                } else {toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }
    const OwnerBookingHistory = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: localStorage.getItem('userId')
            }
            await axios.post(API_URL + 'ownerBookingHistory', requestBody, { headers: header }).then((response) => {
                if (response.data.status === 200) {
                    // console.log(response.data.data)
                    setOwnerBookingHistory(response.data.data)
                } else {toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleClickOpen = (data) => {
        setOpen(true);
        setDeleteData(data)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const cancleBooking = async (e) => {
        e.preventDefault();
        setbtnLoad(true)
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                bookingId: deletedata.id,
            }
            await axios.post(API_URL + 'cancleBookedParking', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    setOpen(false)
                    setbtnLoad(false)
                    BookParkingHistory()
                } else {
                    setOpen(false)
                    setbtnLoad(false)
                    toast.error('Something wrong with transaction details!')
                }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const ownerChangePage = (event: any, newPage: number) => {
        setOwnerPage(newPage);
    };

    const ownerChangeRowsPerPage = (event: any) => {
        setRowsPerOwnerPage(+event.target.value);
        setOwnerPage(0);
    };

    const pagination = (
        <TablePagination
            rowsPerPageOptions={[3, 6, 9, 12]}
            component="div"
            count={bookParkingHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )

    const ownerPagination = (
        <TablePagination
            rowsPerPageOptions={[3, 6, 9, 12]}
            component="div"
            count={ownerBookingHistory.length}
            rowsPerPage={rowsPerOwnerPage}
            page={ownerpage}
            onPageChange={ownerChangePage}
            onRowsPerPageChange={ownerChangeRowsPerPage}
        />
    )
    const Loader = (
        <div className='loader'><img src={loader} loading="lazy" alt='loader' /></div>
    )
    const BookParkingHistoryData = (
        <>
            <Box className="cards" sx={{ width: '100%' }}>
                {load ? (<Box>{Loader}</Box>) : (
                    <>
                        {bookParkingHistory.length !== 0 ? (
                            <>
                                <div>{pagination}</div>
                                {bookParkingHistory
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <Card key={index} className="cards-item">
                                            <CardContent className="cards-item-contents">
                                                <div className="details" style={{ width: '88%' }}>
                                                    <p className="name">{item.parking_name}
                                                        <Rating name="read-only" value={item.averageRating} readOnly />
                                                    </p>
                                                    <span className="address"><span className="label">Address: </span>{item.address}</span>
                                                    <div className="location">
                                                        <span><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}, {item.country}</span>
                                                    </div>
                                                    <div className="booking-time">
                                                        <span className="time"> <span className="label">Start-Time: </span>{moment(item.booking_start_time).format("lll")}</span>
                                                        <span className="time"><span className="label">End-Time: </span>{moment(item.booking_end_time).format("lll")}</span>
                                                    </div>
                                                    {item.booking_cancelled ? (
                                                        <div style={{ borderTop: '1px solid rgba(0,0,0,0.1)', display: 'flex', alignItems: ' center', gap: '1rem', marginTop: '8px', paddingTop: '1rem' }}>
                                                            <img src={calendar} style={{ height: '2rem' }} loading="lazy" alt='loader' />
                                                            <div>
                                                                <p style={{ fontSize: '14px' }}>Your Booking has been cancelled and Refund has been initiated.</p>
                                                                <p style={{ fontSize: '14px' }}>Refund Status:<span style={{ color: ' #bd3f32', fontWeight: '600', background: ' #bd3f3217', padding: '4px 12px', borderRadius: '1rem' }}>{item.refund_status}</span> </p>
                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </div>
                                                <div className="history-actions">
                                                    <p className="billing-date">
                                                        {moment(item.payment_information.billing_date).format("lll")}
                                                    </p>
                                                    <span className="euro"><span className="label">Total Hrs:
                                                    </span>{item.payment_information.total_hrs}</span>
                                                    <span className="discount"><span className="label">discount:
                                                    </span>€{item.payment_information.discount_amount}</span>
                                                    <span className="tax"><span className="label">tax: </span>
                                                        <span>
                                                            €{item.payment_information.tax_amount} <span
                                                                className="rate">({item.payment_information.tax_rate}%)</span>
                                                        </span>
                                                    </span>
                                                    <span className="total"><span className="label">Total:
                                                    </span>€{item.payment_information.total_billing_amount}</span>
                                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                        <button className="primary-btn green-btn" onClick={() => redirectToDetails(item)}>View</button>
                                                        {item.cancellable && !item.booking_cancelled ? (
                                                            <Button variant="outlined" color="error" sx={{ textTransform: "none", borderRadius: '1.5rem' }} onClick={() => handleClickOpen(item)}>
                                                                cancel
                                                            </Button>) : ''}
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card >
                                    ))
                                }
                            </>
                        ) : (
                            <div className="no-parking-found">
                                <p>No parking found</p>
                            </div>
                        )
                        }
                    </>
                )}
            </Box >
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure, you want to cancel this parking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={btnload } onClick={cancleBooking}>
                        {btnload ? (<CircularProgress color="inherit" size={20} />) : ''}
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
    const OwnerParkingHistoryData = (
        <Box className="cards" sx={{ width: '100%' }}>
            {ownerBookingHistory.length !== 0 ? (
                <>
                    <div>{ownerPagination}</div>
                    {ownerBookingHistory
                        .slice(ownerpage * rowsPerOwnerPage, ownerpage * rowsPerOwnerPage + rowsPerOwnerPage)
                        .map((item, index) => (
                            <Card key={index} className="cards-item" onClick={() => redirectToDetails(item)}>
                                <CardContent className="cards-item-contents">
                                    <div className="details" style={{ width: '88%' }}>
                                        <p className="name">{item.parking_name}
                                            <Rating name="read-only" value={item.averageRating} readOnly />
                                        </p>
                                        <span className="address"><span className="label">Address: </span>{item.address}</span>
                                        <div className="location">
                                            <span><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}, {item.country}</span>
                                        </div>
                                        <div className="booking-time">
                                            <span className="time"> <span className="label">Start-Time: </span>{moment(item.booking_start_time).format("lll")}</span>
                                            <span className="time"><span className="label">End-Time: </span>{moment(item.booking_end_time).format("lll")}</span>
                                        </div>
                                    </div>
                                    <div className="history-actions">
                                        <p className="billing-date">
                                            {moment(item.payment_information.billing_date).format("lll")}
                                        </p>
                                        <span className="euro"><span className="label">Total Hrs:
                                        </span>{item.payment_information.total_hrs}</span>
                                        <span className="discount"><span className="label">discount:
                                        </span>€{item.payment_information.discount_amount}</span>
                                        <span className="tax"><span className="label">tax: </span>
                                            <span>
                                                €{item.payment_information.tax_amount} <span
                                                    className="rate">({item.payment_information.tax_rate}%)</span>
                                            </span>
                                        </span>
                                        <span className="total"><span className="label">Total:
                                        </span>€{item.payment_information.total_billing_amount}</span>
                                    </div>
                                </CardContent>
                            </Card >
                        ))
                    }

                </>
            ) : (
                <div className="no-parking-found">
                    <p>No parking found</p>
                </div>
            )}

        </Box >
    )
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="My Booked Parking History" className='tab' />
                        <Tab label="My Offered Parking History" className='tab' />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {BookParkingHistoryData}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {OwnerParkingHistoryData}
                </TabPanel>
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
