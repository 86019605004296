import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Box, Card, CardContent, CardActions, Button, TextField, Autocomplete, MenuItem, Checkbox, FormControlLabel, CircularProgress, Dialog, InputLabel, FormControl,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GoogleMapReact from 'google-map-react';
import AddressAutocomplete from 'mui-address-autocomplete';
import Geocode from "react-geocode";
import axios from 'axios';
import '../../Styles/cards.scss'
import { environment } from '../../environment/environment';
import { getMapOptions } from '../../helpers/map-options'
import MapMarker from '../../helpers/location-map-marker';
import CancelIcon from '@mui/icons-material/Cancel';


export default function UpdateParking({ onRegister }) {
    const googleAPI = environment.googleAPI
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const env = environment.apiImageUrl
    var data = JSON.parse(localStorage.getItem('myData'));
    const [size, setSize] = useState(null);
    const [type, setType] = useState(null);
    const [buildingNo, setBuildingNo] = useState('');
    const [nearestlandmark, setNearestlandmark] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [slot_width, setSlotWidth] = useState('');
    const [slot_length, setSlotLength] = useState('');
    const [slot_height, setSlotHeight] = useState('');
    const [parkingname, setParkingname] = useState('');
    const [zip, setPostalCode] = useState('');
    const [address, setAddress] = useState(null);
    const [parkingprice, setParkingPrice] = useState('');
    const [parkingDescription, setParkingDescription] = useState('');
    const [hasAccessLock, setHasAccessLock] = useState(false)
    const [accessInformation, setAccessInformation] = useState('');
    const [lotNo, setLotNo] = useState('');
    const [allFeatures, setAllFeatures] = useState([]);
    const [featureId, setFeatureId] = useState([]);
    const [deleteImages, setDeleteImages] = useState([]);
    const [deletedFeatures, setDeletedFeatures] = useState([]);
    const previews: Blob[] = [];
    const [latitude, setLat] = useState('')
    const [longitude, setLng] = useState('')
    const [center] = useState({ lat: parseFloat(localStorage.getItem('currentLat')), lng: parseFloat(localStorage.getItem('currentLng')) });
    const [zoom] = useState(7);
    const [maplat, setMapLat] = useState(parseFloat(localStorage.getItem('currentLat')))
    const [maplng, setMapLng] = useState(parseFloat(localStorage.getItem('currentLng')))
    const navigate = useNavigate();
    const myFiles: string[] = [];
    // console.log(data)

    const ParkingType = [
        { value: 'street', viewValue: 'Street-side' },
        { value: 'multilevel', viewValue: 'Multilevel' },
        { value: 'garage', viewValue: 'Garage' }
    ]
    const VehicleType = [
        { value: 'suv', viewValue: 'SUV' },
        { value: 'sedan', viewValue: 'Sedan' },
        { value: 'hatchback', viewValue: 'Hatchback' },
        { value: 'truck', viewValue: 'Truck' },
        { value: 'mini', viewValue: 'Mini' }
    ]


    useEffect(() => {
        GetFeatures()
        setValues()
    }, []);

    useEffect(() => {
        localStorage.removeItem('isUpdate')
        // localStorage.removeItem('myData')
    }, []);

    const removeImage = (id) => {
        deleteImages.push(id)
        // console.log('deleted', deleteImages)
        document.getElementById(id).remove();
    };

    const setValues = () => {
        // data.featureList.map((item: any) => {
        //     featureId.push(item.id)
        // });
        const oldFeatureId = []
        data.featureList.map((item: any) => {
            oldFeatureId.push(item.id)
        });
        setFeatureId(oldFeatureId)
        setParkingname(data.parking_name)
        setParkingPrice(data.parking_price)
        setAddress(data.address)
        setBuildingNo(data.building_no)
        setCity(data.city)
        setCountry(data.country)
        setHasAccessLock(data.has_access_lock)
        setAccessInformation(data.access_information)
        setLotNo(data.slot_no)
        setNearestlandmark(data.nearest_mark === "null" ? '' : data.nearest_mark)
        setParkingDescription(data.parking_description)
        setPostalCode(data.postal_code)
        setSize(data.size)
        setType(data.type)
        setSlotHeight(data.slot_height === "null" ? '' : data.slot_height)
        setSlotLength(data.slot_length === "null" ? '' : data.slot_length)
        setSlotWidth(data.slot_width === "null" ? '' : data.slot_width)

    }

    const GetFeatures = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: localStorage.getItem('userId')
            }
            await axios.post(API_URL + 'allFeaturesList', requestBody, { headers: header }).then((response) => {
                // console.log(response, response.data.featureDetails)
                if (response.data.statusCode === 200) {
                    setAllFeatures(response.data.featureDetails)
                } else { alert(response.data.message) }
            })
        } catch (error) {
            // console.log('error occured*****', error.response.data)
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleChange = (event: SelectChangeEvent<typeof featureId>) => {
        const {
            target: { value },
        } = event;
        setFeatureId(value);
    };
    // console.log(featureId)
    const onFileChanged = (event) => {
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                previews.push(event.target.files[i]);
                myFiles.push(event.target.files[i]);
            }
        }
        // console.log(typeof (previews), 'preview', previews)
    }

    const position = (address) => {
        Geocode.setApiKey(googleAPI)
        Geocode.fromAddress(address).then(
            (response) => {
                setLat(response.results[0].geometry.location.lat)
                setLng(response.results[0].geometry.location.lng)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const getAddress = (lat, lng) => {
        setLat(lat)
        setLng(lng)
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setAddress(response.results[0].formatted_address)
            },
        );
    }
    // console.log(latitude, longitude, address);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMapLat(parseFloat(localStorage.getItem('currentLat')))
        setMapLng(parseFloat(localStorage.getItem('currentLng')))
    };

    const OnSubmit = async (e) => {
        // console.log(featureId)
        e.preventDefault();
        setLoad(true)
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': '*',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            const formData = new FormData();
            formData.append('parkingId', data.id);
            formData.append('parkingname', parkingname);
            formData.append('size', size);
            formData.append('type', type);
            formData.append('buildingNo', buildingNo);
            formData.append('address1', address);
            formData.append('latitude', latitude ? latitude : data.latitude)
            formData.append('longitude', longitude ? longitude : data.longitude);
            formData.append('nearestlandmark', nearestlandmark);
            formData.append('city', city);
            formData.append('country', country);
            formData.append('zip', zip);
            formData.append('slot_width', slot_width);
            formData.append('slot_length', slot_length);
            formData.append('slot_height', slot_height);
            formData.append('parking_price', parkingprice);

            if (API_URL.includes('plstest')) {
                previews.map((data: any) => {
                    formData.append('photo', data, data.name);
                    // console.log('photo data', data, data.name)
                });
            } else {
                for (var i = 0; i < myFiles.length; i++) {
                    formData.append("photo[]", myFiles[i]);
                }
            }
            formData.append('parkingDescription', parkingDescription);
            formData.append('hasAccessLock', hasAccessLock);
            formData.append('accessInformation', accessInformation);
            formData.append('userid', localStorage.getItem('userId'));
            formData.append('slotNo', lotNo);
            formData.append('featureId', JSON.stringify(featureId));
            formData.append('deleteImages', JSON.stringify(deleteImages));
            formData.append('deletedFeaturesId', JSON.stringify(deletedFeatures));
            formData.append('action', 'update');
            await axios.post(API_URL + 'parkingRegistration', formData, { headers: header }).then((response) => {
                // console.log(response.data)
                if (response.data.statusCode === 200) {
                    setLoad(false)
                    localStorage.removeItem('setLocation')
                    { onRegister() }
                } else { alert(response.data.message) }
            })
        } catch (error) {
            console.log(error, 'error')
        }
    }
    const GoogleMap = (
        <Card>
            <div style={{ height: '500px' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: environment.googleAPI }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={getMapOptions}
                    onClick={ev => {
                        setMapLat(ev.lat)
                        setMapLng(ev.lng)
                        getAddress(ev.lat, ev.lng)
                        // console.log("latitide = ", ev.lat,ev.lng);
                    }}
                >
                    <MapMarker
                        position={{ lat: maplat, lng: maplng }}
                        tooltip={address ? address : localStorage.getItem('currentAddress')}
                        lat={maplat}
                        lng={maplng}
                    >
                    </MapMarker>
                </GoogleMapReact>
            </div>
        </Card >
    );
    const UpdateParkingForm = (
        <>
            <Card className='register-card'>
                <h2 className="register-card-head">  Update Your Parking</h2>
                <form onSubmit={OnSubmit}>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'space-between', maxWidth: '700px' }}>
                            <TextField required
                                id="parkingname"
                                label="Parking Lot Name"
                                placeholder='Parking Name'
                                value={parkingname}
                                onChange={(e) => {
                                    setParkingname(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '48%', paddingBottom: '16px' }}>
                                <AddressAutocomplete required
                                    apiKey={googleAPI}
                                    label="Location"
                                    fields={['geometry']}
                                    value={address}
                                    onChange={(_, location) => {
                                        // setAddress(location);
                                        setAddress(location.formatted_address)
                                        position(location.formatted_address)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Location" required />}
                                    disableClearable
                                />
                                <div className="map-location" onClick={handleClickOpen}>
                                    <MyLocationIcon sx={{ color: '#0099ff' }} />
                                    <span>select your location</span>
                                </div>
                            </div>
                            <TextField required
                                id="country"
                                label="country"
                                placeholder='Country'
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <Autocomplete
                                disablePortal
                                id="type"
                                value={type}
                                options={ParkingType.map((option) => option.viewValue)}
                                onChange={(e, value) => {
                                    setType(value);
                                }}
                                renderInput={(params) => <TextField {...params} label="Parking Type" required />}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <Autocomplete
                                disablePortal
                                id="size"
                                value={size}
                                options={VehicleType.map((option) => option.viewValue)}
                                onChange={(e, value) => {
                                    setSize(value);
                                    // console.log(value)
                                }}
                                renderInput={(params) => <TextField {...params} label="Vehicle Type" required />}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField required
                                id="postalcode"
                                label="Postal Code"
                                placeholder='Postal Code'
                                value={zip}
                                onChange={(e) => {
                                    setPostalCode(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField required
                                id="city"
                                label="City"
                                placeholder='City'
                                value={city}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField required
                                id="buildingNo"
                                label="Building No."
                                placeholder='Building No'
                                value={buildingNo}
                                onChange={(e) => {
                                    setBuildingNo(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField required
                                id="parkingprice"
                                label="Parking price € /Hour"
                                placeholder='Parking price € /Hour'
                                value={parkingprice}
                                onChange={(e) => {
                                    setParkingPrice(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField required
                                id="lotNo"
                                label="Lot No."
                                placeholder='Parking Lot No.'
                                value={lotNo}
                                onChange={(e) => {
                                    setLotNo(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField
                                id="nearestlandmark"
                                label="Landmark"
                                placeholder='Nearest Landmark'
                                value={nearestlandmark}
                                onChange={(e) => {
                                    setNearestlandmark(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField
                                id="slot_width"
                                label="Parking Lot width (in cm)"
                                placeholder='Parking Lot width (in cm)'
                                value={slot_width}
                                onChange={(e) => {
                                    setSlotWidth(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField
                                id="slot_height"
                                label="Parking Lot height (in cm)"
                                placeholder='Parking Lot height (in cm)'
                                value={slot_height}
                                onChange={(e) => {
                                    setSlotHeight(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField
                                id="slot_length"
                                label="Parking Lot length (in cm)"
                                placeholder='Parking Lot length (in cm)'
                                value={slot_length}
                                onChange={(e) => {
                                    setSlotLength(e.target.value);
                                }}
                                sx={{ width: '48%', paddingBottom: '16px' }}
                            />
                            <TextField required
                                id="parkingDescription"
                                label="Parking Description"
                                placeholder='Parking Description'
                                value={parkingDescription}
                                onChange={(e) => {
                                    setParkingDescription(e.target.value);
                                }}
                                sx={{ width: '100%', paddingBottom: '16px' }}
                            />

                            <FormControl sx={{ width: '100%', paddingBottom: '16px' }}>
                                <InputLabel id="features">Select Features</InputLabel>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={featureId}
                                    labelId="features"
                                    id="demo-simple-select-helper"
                                    label="Select Features"
                                    onChange={handleChange}
                                // renderValue={(params) => <TextField {...params} label="Select Features" />}
                                >
                                    {allFeatures.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            <Checkbox
                                                checked={
                                                    featureId.findIndex(i => i === item.id) >= 0
                                                }
                                            />
                                            {item.feature_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControlLabel control={
                                <Checkbox
                                    checked={hasAccessLock ? true : false}
                                    onChange={(e, value) => {
                                        setHasAccessLock(value);
                                        // console.log(value,typeof(value))
                                    }}
                                />} label="Has Access lock?"
                                sx={{ width: '100%', paddingBottom: '16px' }}
                            />

                            {hasAccessLock ? (<TextField required
                                id="accessInformation"
                                label="Access Information"
                                placeholder='Access Information'
                                value={accessInformation}
                                onChange={(e) => {
                                    setAccessInformation(e.target.value);
                                }}
                                sx={{ width: '100%', paddingBottom: '16px' }}
                            />) : null}
                        </Box>
                    </CardContent>
                    <input id="files" multiple id="files" type="file" onChange={onFileChanged} />
                    <div className="register-card-preview">
                        {data.user_parking_images.map((img) => (
                            <div key={img.id} id={img.id} className="register-card-preview-box">
                                <img  src={env + img.imageURL} loading="lazy" alt='uploaded' className='upload-img'
                                />
                                <CancelIcon onClick={() => removeImage(img.id)} className="cancel"/>
                            </div>
                        ))}

                    </div>

                    <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button type="submit" variant="contained" disabled={load} sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '50%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                            {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                            Update Parking
                        </Button>
                    </CardActions>
                </form>
            </Card>
            <Dialog sx={{
                "& .MuiDialog-paper": {
                    minWidth: '50%'
                }
            }}
                open={open}
                onClose={handleClose}>
                <Card sx={{ borderRadius: '25px' }}>
                    <CardContent>{GoogleMap} </CardContent>
                    <CardActions sx={{ display: 'flex', gap: '30px', justifyContent: 'center' }}>
                        <Button sx={{ textTransform: "none", color: '#ffffff', borderRadius: '1.5rem', backgroundColor: '#34b759' }} onClick={handleClose}>Confirm</Button>
                        <Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", borderRadius: '1.5rem' }}>
                            cancel
                        </Button>
                    </CardActions>

                </Card>
            </Dialog>
        </>
    );

    return (
        <Box sx={{ maxWidth: '730px' }}>
            {UpdateParkingForm}
        </Box>
    );
}
