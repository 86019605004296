import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { Toolbar, Stack, Box, Paper, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import '../../Styles/global.scss'
import logo from '../../Images/logo1.png'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

export default function LogOut({ redirectToHome, logOut }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userName = localStorage.getItem('userName')
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const displayHeader = () => {
        return (
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', boxShadow: "0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933" }}>
                <Stack>{headerLogo}</Stack>
                <Stack>{menuItems}</Stack>
            </Toolbar>
        );
    };
    const headerLogo = (
        <div><img src={logo} loading="lazy" alt='logo' className="header-logo" onClick={redirectToHome} /></div>
    );
    const menuItems = (
        <Box sx={{ display: 'flex', gap:'10px'}}>
            <Typography variant="subtitle1">{userName}</Typography>
            <AccountCircleRoundedIcon onClick={handleClick} />
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
        </Box>
    )
    return (
        <Box sx={{ width: '100%' }}>
            <Stack>
                {displayHeader()}
            </Stack>
        </Box>
    );
}