import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Card } from "@mui/material";
import Marker from './map-marker'
import { environment } from '../environment/environment';

export default function GoogleMap() {
    const latitude = localStorage.getItem('latitude') ? parseFloat(localStorage.getItem('latitude')) : parseFloat(localStorage.getItem('currentLat'));
    const longitude = localStorage.getItem('longitude') ? parseFloat(localStorage.getItem('longitude')) : parseFloat(localStorage.getItem('currentLng'));
    const [center] = useState({ lat: latitude, lng: longitude });
    const [zoom] = useState(7);
    const markers = JSON.parse(localStorage.getItem('myMarkers'));
    // console.log('markers', markers, markers.length)
    const uniqueAdress = [];
    const unique = markers ? markers.filter(element => {
        const isDuplicate = uniqueAdress.includes(element.name);
        if (!isDuplicate) {
            uniqueAdress.push(element.name);
            return true;
        }
        return false;
    }) : []
    // console.log('unique address', unique);

    const GoogleMap = (
        <Card>
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: environment.googleAPI }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                >
                    {markers && markers.length !== 0 ? (
                        <>
                            {unique.map(({ id, name, position }) => (
                                <Marker
                                    key={id}
                                    position={position}
                                    tooltip={name}
                                    lat={position.lat}
                                    lng={position.lng }
                                >
                                </Marker>
                            ))}
                        </>) : (<Marker
                            key='1'
                            lat={latitude}
                            lng={longitude}
                            tooltip={localStorage.getItem('location')}
                        >
                        </Marker>)}
                </GoogleMapReact>
            </div>
        </Card>
    );

    return (

        <Box>{GoogleMap}</Box>

    );
}
