import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HistoryIcon from '@mui/icons-material/History';
import '../../Styles/global.scss'
import RegisterParking from './register-parking';
import UpdateParking from './update-parking'
import MyParking from './my-parking';
import OfferedParking from './offered-parking'
import HistoryParking from './parking-history';
import DashboardMain from './dashboard-main';

const drawerWidth = 240;
const sibarItems = ['Dashboard', 'Register Parking', 'My Parking', 'Offered Parking', 'Parking History']
export default function Dashboard() {
    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem('fromMyParking') ? 2 : localStorage.getItem('action') === 'offerslot' ? 3 : localStorage.getItem('action') === 'history' ? 4 : 0);

    useEffect(() => {
        window.onpopstate = () => {
            localStorage.removeItem('action')
            localStorage.removeItem('setLocation')
        }
    }, []);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        localStorage.removeItem('action')
        localStorage.removeItem('setLocation')
        localStorage.removeItem('fromMyParking')
    };

    const onMyParking = () => {
        setSelectedIndex(2);
    }
    const onUpdateParking = (data) => {
        setSelectedIndex(1);
        localStorage.setItem('isUpdate', true)
        localStorage.setItem('myData', JSON.stringify(data));
    }
    const Sidebar = (
        <Drawer variant="permanent" sx={{
            flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', position: 'initial', border: 'none' },
        }} className="sidebar">
            <Box sx={{ bgcolor: 'background.paper' }}>
                <List component="nav" aria-label="main mailbox folders">
                    {sibarItems.map((text, index) => (
                        <ListItemButton
                            key={text}
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}
                        >
                            <ListItemIcon>
                                {index === 0 ? < DashboardIcon /> : ''}
                                {index === 1 ? <AppRegistrationIcon /> :''}
                                {index === 2 ? <HomeIcon /> :''}
                                {index === 3 ? <CalendarTodayIcon /> :''}
                                {index === 4 ? <HistoryIcon/> :''}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        </Drawer>

    )
    const MainComponent = (
        <Box component="main" sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1, p: 3, maxWidth: selectedIndex === 1 ? '100%' : '1200px'}}>
            {
                (() => {
                    if (selectedIndex === 0)
                        return (<DashboardMain/>)
                    else if (selectedIndex === 1)
                        if (localStorage.getItem('isUpdate')) {
                            return (<UpdateParking onRegister={onMyParking} />)
                        } else {
                            return (<RegisterParking onRegister={onMyParking} />)
                        }

                    else if (selectedIndex === 2)
                        return (<MyParking setData={onUpdateParking} />)
                    else if (selectedIndex === 3)
                        return (<OfferedParking />)
                    else if (selectedIndex === 4)
                        return (<HistoryParking />)
                    else
                        return (<MyParking setData={onUpdateParking} />)
                })()
            }
        </Box>
    )
    return (
        <Box sx={{ display: 'flex', backgroundColor: '#0099ff05' }}>
            <CssBaseline />
            {Sidebar}
            {MainComponent}
        </Box>
    );
}