import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, CardActions, Button, TextField, Autocomplete, MenuItem, Checkbox, FormControlLabel, Typography, OutlinedInput, InputLabel, FormControl, CircularProgress } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment/moment';
import axios from 'axios';
import '../../Styles/cards.scss'
import { environment } from '../../environment/environment';

export default function BookParking(props) {
    const [load, setLoad] = useState(false);
    const [startBookTime, setStartBookDate] = useState(props.summarydata.start_time);
    const [endBookTime, setEndBookDate] = useState(props.summarydata.end_time);
    const [size, setSize] = useState(null);
    const [registration, setRegistration] = useState('');
    const [brand, setBrand] = useState('');
    const [vehicleDescription, setVehicleDescription] = useState('');
    const [clienttoken, setClientToken] = useState('');
    const navigate = useNavigate();

    const VehicleType = [
        { value: 'suv', viewValue: 'SUV' },
        { value: 'sedan', viewValue: 'Sedan' },
        { value: 'hatchback', viewValue: 'Hatchback' },
        { value: 'truck', viewValue: 'Truck' },
        { value: 'mini', viewValue: 'Mini' }
    ]

    useEffect(() => {
        getToken()
    }, []);
    const getToken = async (e) => {
        // const API_URL = 'https://plstest.nntx-lab.com/api/brainTreeClientToken'
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            await axios.get(API_URL + 'brainTreeClientToken', { headers: header }).then((response) => {
                // console.log('client token', response.data)
                setClientToken(response.data.token)
            })
        } catch (error) {
            alert('error occured')
        }
    }

    const OnSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                startTime: startBookTime,
                endTime: endBookTime,
                clientId: props.userId,
                parkingId: props.parkingId,
                country: props.country,
                discountCode: ""

            }
            await axios.post(API_URL + 'bookingAmountCalculation', requestBody, { headers: header }).then((response) => {
                if (response.data.data) {
                    response.data.data['brand'] = brand
                    response.data.data['regNo'] = registration
                    response.data.data['size'] = size
                    response.data.data['vehicleDescription'] = vehicleDescription
                    response.data.data['clientToken'] = clienttoken
                    response.data.data['userId'] = props.userId
                    response.data.data['country'] = props.country
                    localStorage.setItem('myBookingData', JSON.stringify(response.data.data));
                    setLoad(false)
                    navigate("/summary");
                } else {
                    alert(response.data.message)
                    setLoad(false)
                }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const BookParkingForm = (
        <Card className='register-card'>
            <h2 className="register-card-head">Book Parking</h2>
            <form onSubmit={OnSubmit}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px', maxWidth: '700px' }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker sx={{ border: 'none' }}
                                renderInput={(params) => <TextField {...params} />}
                                label="Arriving Time"
                                value={startBookTime}
                                placeholder="Arriving Time"
                                onChange={(newStart) => {
                                    setStartBookDate(newStart);
                                }}
                                minutesStep={30}
                                minDateTime={moment(props.summarydata.start_time)}
                                maxDateTime={moment(props.summarydata.end_time)}
                            />
                            <DateTimePicker
                                renderInput={(params) => <TextField {...params} />}
                                label="Leaving Time"
                                value={endBookTime}
                                placeholder="Leaving Time"
                                onChange={(newEnd) => {
                                    setEndBookDate(newEnd);
                                }}
                                minutesStep={30}
                                minDateTime={moment(props.summarydata.start_time)}
                                maxDateTime={moment(props.summarydata.end_time)}
                            />
                        </LocalizationProvider>
                        <TextField required
                            id="regno"
                            label="Vehicle Registration Number"
                            placeholder='Vehicle Registration Number'
                            value={registration}
                            onChange={(e) => {
                                setRegistration(e.target.value);
                            }}
                        />
                        <TextField required
                            id="brand"
                            label="Brand"
                            placeholder='Vehicle Brand'
                            value={brand}
                            onChange={(e) => {
                                setBrand(e.target.value);
                            }}
                        />
                        <Autocomplete
                            disablePortal
                            id="size"
                            options={VehicleType.map((option) => option.viewValue)}
                            onChange={(e, value) => {
                                setSize(value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Vehicle Type" required />}
                        />
                        <TextField required
                            id="desc"
                            label="Vehicle Description"
                            placeholder='Vehicle Description'
                            value={vehicleDescription}
                            onChange={(e) => {
                                setVehicleDescription(e.target.value);
                            }}
                        />
                    </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button type="submit" variant="contained" disabled={load} sx={{ textTransform: "none", width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                        {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                        Book Parking
                    </Button>
                </CardActions>
            </form>
        </Card>
    );

    return (
        <Box sx={{ maxWidth: '730px' }}>
            {BookParkingForm}
        </Box>
    );
}
