import React, { useState, useEffect } from 'react';
import '../../Styles/global.scss'
import '../../Styles/cards.scss'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, Rating, TextField, Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import { environment } from '../../environment/environment';
import PlaceIcon from '@mui/icons-material/Place';
import GoogleMap from '../../helpers/google-map'
import loader from '../../Images/loading.gif'
import signin from '../../Images/login.png'
import axios from 'axios';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment/moment';
import Address from '../../helpers/address';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Search() {
    const env = environment.apiImageUrl
    const [open, setOpen] = useState(false);
    const [address, setAddress] = useState('');
    const [startTime, setStartDate] = useState(localStorage.getItem('startTime') !== 'null' ? localStorage.getItem('startTime') : null);
    const [endTime, setEndDate] = useState(localStorage.getItem('endTime') !== 'null' ? localStorage.getItem('endTime') : null);
    const [searchParking, setSearchParking] = useState([]);
    const [load, setLoad] = useState(true);
    const location: Array[] = [];
    const lat = localStorage.getItem('latitude') ? parseFloat(localStorage.getItem('latitude')) : parseFloat(localStorage.getItem('currentLat'));
    const long = localStorage.getItem('longitude') ? parseFloat(localStorage.getItem('longitude')) : parseFloat(localStorage.getItem('currentLng'));
    const currentLocation = [
        {
            id: 'map',
            name: localStorage.getItem('location'),
            position: { lat: lat, lng: long }
        }
    ]
    const navigate = useNavigate();

    useEffect(() => {
        OnSearch()
        setLoad(true)
        // console.log('location',location)
        localStorage.setItem('myMarkers', JSON.stringify(location));
        localStorage.removeItem('fromHistroy')
        localStorage.removeItem('fromMyParking')
        window.onpopstate = () => {
            localStorage.removeItem('setLocation')
        }
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const onLocation = (place) => {
        setAddress(place ? place : localStorage.getItem('location'));
    }

    const redirectToDetails = (item) => {
        localStorage.setItem('myData', JSON.stringify(item));
        if (localStorage.getItem('token')) {
            navigate('/details')
        } else {
            handleClickOpen()
        }
    }

    const redirectToLogin = () => {
        localStorage.setItem('fromSearch', true);
        navigate('/login')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        localStorage.setItem('startTime', startTime)
        localStorage.setItem('endTime', endTime)
        setLoad(true)
        if (moment(endTime).isAfter(moment(startTime))) {
            OnSearch()
        }
        else if ((startTime === endTime) || ((startTime === endTime) && (startTime === 'Invalid date' || endTime === 'Invalid date'))) {
            localStorage.setItem('startTime', null)
            localStorage.setItem('endTime', null)
            OnSearch()
        }
        else { toast.error('End date must be greater than start date.') }
    }
    const OnSearch = async (e) => {
        // e.preventDefault();
        const API_URL = environment.apiBaseUrl
        const header = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            // console.log( new Date(localStorage.getItem('startTime')).toISOString() )
            let requestBody = {
                start_date: localStorage.getItem('startTime') !== 'null' ?  new Date(localStorage.getItem('startTime')).toISOString() : "",
                end_date: localStorage.getItem('endTime') !== 'null' ?  new Date(localStorage.getItem('endTime')).toISOString() : "",
                latitude: localStorage.getItem('latitude') ? parseFloat(localStorage.getItem('latitude')) : parseFloat(localStorage.getItem('currentLat')),
                longitude: localStorage.getItem('longitude') ? parseFloat(localStorage.getItem('longitude')) : parseFloat(localStorage.getItem('currentLng')),
            }
            await axios.post(API_URL + 'searchParking2', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    setTimeout(() => {
                        setLoad(false)
                    }, 1000);
                    setSearchParking(response.data.response)
                    searchParking.map((item) => (location.push({
                        id: item.id,
                        name: item.address,
                        position: { lat: item.latitude, lng: item.longitude }
                    }
                    )
                    ))
                    if (location.length !== 0) {
                        localStorage.setItem('myMarkers', JSON.stringify(location));
                    } else { localStorage.setItem('myMarkers', JSON.stringify(currentLocation)); }
                    // console.log('location', location)
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            alert('error occured')
        }
    }
    const Search = (
        <form onSubmit={OnSearch}>
            <Box sx={{ padding: '16px', display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'center', boxShadow: "0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933" }}>
                <div style={{ width: '500px' }}>
                    <Address myLocation={onLocation} /></div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker sx={{ border: 'none' }}
                        renderInput={(params) => <TextField {...params} />}
                        label="Arriving Time"
                        value={startTime}
                        placeholder="Arriving Time"
                        onChange={(newStart) => {
                            setStartDate(newStart);
                            // localStorage.setItem('startTime', startTime)
                        }}
                        minutesStep={30}
                        minDateTime={moment()}
                    />
                    <DateTimePicker
                        renderInput={(params) => <TextField {...params} />}
                        label="Leaving Time"
                        value={endTime}
                        placeholder="Leaving Time"
                        onChange={(newEnd) => {
                            setEndDate(newEnd);
                            // localStorage.setItem('endTime', endTime)
                        }}
                        minutesStep={30}
                        minDateTime={moment()}
                    />
                </LocalizationProvider>
                <Button type="submit" variant="contained" sx={{ textTransform: "none", width: '200px', borderRadius: '1.5rem', height: '40px', backgroundColor: '#34b759' }} onClick={handleSubmit}>Search Parking</Button>
            </Box>
        </form>
    );
    const Loader = (
        <div className='loader'><img src={loader} loading="lazy" alt='loader' /></div>
    )
    const SearchedData = (
        <div style={{ display: 'flex', gap: '15px', height: '80vh' }}>
            <Box className="cards">
                {load ? (<Box>{Loader}</Box>) : (
                    <> {searchParking.length !== 0 ?
                        (<>
                            {searchParking.map((item, index) => (
                                <Card key={index} style={{ backgroundColor: item.grade === 'grade_2' ? '#f3f3f3' : item.grade === 'grade_3' ? '#e5e5e5' : '#ffffff' }} className="cards-item">
                                    <CardContent className="cards-item-contents">
                                        <div className="gallery">
                                            {item.user_parking_images.length !== 0 ? (
                                                <img src={env + item.user_parking_images[0].imageURL} loading="lazy" alt='uploaded' className='gallery-img' />
                                            ) : (
                                                <div className="default-img">
                                                    <p>No Image Uploaded</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className="details">
                                            <p className="name">{item.parking_name}
                                                <Rating name="read-only" value={item.averageRating} readOnly />
                                            </p>
                                            <span className="address"><span className="label">Address: </span>{item.address}</span>
                                            <div className="location">
                                                <span><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}, {item.country}</span>
                                            </div>
                                            <div className="booking-time">
                                                <span className="time"> <span className="label">Start-Time: </span>{moment(item.start_time).format("lll")}</span>
                                                <span className="time"><span className="label">End-Time: </span>{moment(item.end_time).format("lll")}</span>
                                            </div>
                                        </div>
                                        <div className="actions">
                                            {item.parking_lot_status ? (
                                                <>
                                                    {item.parking_lot_status === 'available' ? (
                                                        <span className="badge" style={{ padding:'7px',backgroundColor: '#28a745' }}>{item.parking_lot_status}</span>
                                                    ) : item.parking_lot_status === 'partially booked' ? (
                                                        <span className="badge" style={{padding:'7px', backgroundColor: '#ffc107' }}>{item.parking_lot_status}</span>
                                                    ) : (<span className="badge" style={{ padding:'7px',backgroundColor: '#ffc107' }}>{item.parking_lot_status}</span>
                                                    )
                                                    }
                                                </>
                                            ) : ('')}
                                            <span className="euro"><span>€{item.price_variable}</span><sub>/hr</sub> <span className='subinfo'>(prices may vary)</span> </span>
                                            {/* <Link to={'/details'}> */}
                                            <button className="primary-btn" onClick={() => redirectToDetails(item)}>Details</button>
                                            {/* </Link> */}
                                        </div>
                                    </CardContent>
                                </Card >
                            ))
                            }
                        </>)
                        : (
                            <div className="no-parking-found">
                                <p>No parking found</p>
                            </div>
                        )}
                    </>
                )}

            </Box >
            <Dialog
                open={open}
                sx={{
                    "& .MuiDialog-paper": {
                        boxShadow: '0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933',
                        border: '1px solid #dddfe2',
                        borderRadius: '8px',
                        padding: '20px',
                        alignItems: 'center'
                    }
                }}
                onClose={handleClose}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px', fontSize: '1rem' }}>
                    <img src={signin} loading="lazy" alt='login' style={{ width: '50px', height: '50px' }} />
                    <p>  Please Login to book parking.</p>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: "none", color: '#ffffff', borderRadius: '1.5rem', backgroundColor: '#34b759' }} onClick={() => redirectToLogin()}>Login</Button>
                    <Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", borderRadius: '1.5rem' }}>
                        cancel
                    </Button>

                </DialogActions>
            </Dialog>
            <Card sx={{ width: '35%' }}><GoogleMap /></Card>
        </div>

    )
    return (
        <>
            <Box sx={{ paddingBottom: '30px' }}>{Search}</Box>
            <Box> {SearchedData}</Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
