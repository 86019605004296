import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, CardActions, Button, TextField, CircularProgress } from "@mui/material";
import axios from 'axios';
import '../../Styles/global.scss'
import park from '../../Images/parking-board.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    const setResetLink = async (e) => {
        e.preventDefault();
        setLoad(true)
        const API_URL = 'https://plslaravel.netnautix-sandbox.com/api/sendResetLinkEmail'
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            await axios.post(API_URL, {
                email: email,
                domain: "https://www.plsreact.nntx-lab.com"
            }, { headers: header }).then((response) => {
                toast.error('Password reset link sent to email, Please verify first...')
                setLoad(false)
                navigate("/login");
            })
        } catch (error) {
            setLoad(false)
            toast.error('error occured')
        }
    }

    const forgotPasswordForm = (
        <Card sx={{ height: 'fit-content' }} className="auth-card">
            <div className="auth-card-content">
                <form onSubmit={setResetLink}>
                    <div className="auth-card-content-title">
                        <h2 className="auth-card-content-title-head">Forgot Password
                            <span className="auth-card-content-title-head-desc">email verification</span>
                        </h2>
                    </div>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <TextField required
                                id="email"
                                label="Email"
                                placeholder='Enter Your Email'
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Box>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                        <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                            {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                            Submit
                        </Button>
                    </CardActions>
                </form>
            </div>
            <div>
                <img src={park} loading="lazy" alt='img' style={{ width: '536px' }} />
            </div>

        </Card>
    );

    return (
        <>
            <Box className='auth-backgroud' sx={{ paddingTop: '100px', justifyContent: 'center' }}>
                {forgotPasswordForm}
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
