import { useState, useEffect } from 'react'
import { Box, Card, CardContent, Button, CardActions, TextField, FormControlLabel, Switch, CircularProgress } from "@mui/material";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { environment } from '../../environment/environment';
import GoogleMapReact from 'google-map-react';
import AddressAutocomplete from 'mui-address-autocomplete';
import Geocode from "react-geocode";
import '../../Styles/details.scss'
import MapMarker from '../../helpers/location-map-marker';
import { getMapOptions } from '../../helpers/map-options'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Profile() {
    const googleAPI = environment.googleAPI
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [isupdate, setUpdate] = useState(false);
    const [userDetails, setProfile] = useState([]);
    const [fullname, setFullName] = useState();
    const [zip, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [address, setAddress] = useState('');
    const [enableWhatsapp, setEnableWhatsapp] = useState(false)
    const [latitude, setLat] = useState('')
    const [longitude, setLng] = useState('')
    const [center] = useState({ lat: parseFloat(localStorage.getItem('currentLat')), lng: parseFloat(localStorage.getItem('currentLng')) });
    const [zoom] = useState(7);
    const [maplat, setMapLat] = useState(parseFloat(localStorage.getItem('currentLat')))
    const [maplng, setMapLng] = useState(parseFloat(localStorage.getItem('currentLng')))

    const navigate = useNavigate();

    useEffect(() => {
        GetProfile()
    }, []);

    const position = (address) => {
        Geocode.setApiKey(googleAPI)
        Geocode.fromAddress(address).then(
            (response) => {
                setLat(response.results[0].geometry.location.lat)
                setLng(response.results[0].geometry.location.lng)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const getAddress = (lat, lng) => {
        setLat(lat)
        setLng(lng)
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setAddress(response.results[0].formatted_address)
            },
        );
    }

    // console.log(latitude, longitude, address);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMapLat(parseFloat(localStorage.getItem('currentLat')))
        setMapLng(parseFloat(localStorage.getItem('currentLng')))
    };

    const openUpdate = () => {
        setUpdate(true);
        setDelete(false)
        setValues();
    };
    const closeUpdate = () => {
        setDelete(true)
    }
    const redirectToLogin = () => {
        navigate("/login");
    }
    const GetProfile = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: localStorage.getItem('userId')
            }
            await axios.post(API_URL + 'getMyProfile', requestBody, { headers: header }).then((response) => {
                if (response.data.status === 200) {
                    setProfile(response.data.userDetails)
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const setValues = () => {
        setFullName(userDetails.fullName)
        setPostalCode(userDetails.postal_code)
        setPhone(userDetails.phone_no)
        setEmail(userDetails.email_id)
        setUsername(userDetails.userName)
        setAddress(userDetails.fullAddress)
        // setEnableWhatsapp(userDetails.whatsappNotification == "1" ? true : false)
    }

    const OnSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        const API_URL = environment.apiBaseUrl
        try {
            let requestBody = {
                userId: localStorage.getItem('userId'),
                fullName: fullname,
                fullAddress: address,
                phoneNumber: phone,
                postalCode: zip,
                emailId: email,
                userName: username,
                whatsappNotification: enableWhatsapp,
                latitude: latitude,
                longitude: longitude,
                action: isDelete ? "delete" : "update"
            }

            // navigate("/dashboard");

            await axios.post(API_URL + 'updateprofile', requestBody).then((response) => {
                if (response.data) {
                    setLoad(false)
                    navigate("/dashboard");
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const profile = (
        <Box className="parking-profile">
            <Card className="parking-profile-card">
                <AccountCircleRoundedIcon sx={{ color: '#0099ff', height: '60px', width: '60px' }} />
                <h2>Profile</h2>
                <CardContent className="parking-profile-card-item">
                    <p><span className="label">Full Name : </span>{userDetails.fullName}</p>
                    <p><span className="label">Username : </span>{userDetails.userName}</p>
                    <p><span className="label">Address : </span>{userDetails.fullAddress}</p>
                    <p><span className="label">Postal Code : </span>{userDetails.postal_code}</p>
                    <p><span className="label">Email Id : </span>{userDetails.email_id}</p>
                    <p><span className="label">Phone : </span>{userDetails.phone_no}</p>
                </CardContent>
                <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '50%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }} onClick={openUpdate}>
                        Update Profile
                    </Button>
                </CardActions>
            </Card>
        </Box >
    );
    const GoogleMap = (
        <Card>
            <div style={{ height: '500px' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: environment.googleAPI }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={getMapOptions}
                    onClick={ev => {
                        setMapLat(ev.lat)
                        setMapLng(ev.lng)
                        getAddress(ev.lat, ev.lng)
                        // console.log("latitide = ", ev.lat,ev.lng);
                    }}
                >
                    <MapMarker
                        position={{ lat: maplat, lng: maplng }}
                        tooltip={address ? address : localStorage.getItem('currentAddress')}
                        lat={maplat}
                        lng={maplng}
                    >
                    </MapMarker>
                </GoogleMapReact>
            </div>
        </Card >
    );
    const editProfile = (
        <Box className="parking-profile">
            <Card className="parking-profile-card" sx={{ maxWidth: '450px' }}>
                <AccountCircleRoundedIcon sx={{ color: '#0099ff', height: '60px', width: '60px' }} />
                <h2>Update Profile</h2>
                <form onSubmit={OnSubmit}>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <TextField required
                                id="fullname"
                                label="Full Name"
                                placeholder='Full Name'
                                value={fullname}
                                onChange={(e) => {
                                    setFullName(e.target.value);
                                }}
                            />
                            <AddressAutocomplete required
                                apiKey={googleAPI}
                                label="Location"
                                fields={['geometry']}
                                value={address}
                                onChange={(_, location) => {
                                    // setAddress(location);
                                    setAddress(location.formatted_address)
                                    position(location.formatted_address)
                                }}
                                renderInput={(params) => <TextField {...params} label="Select Location" required />}
                                disableClearable
                            />

                            <div className="map-location" onClick={handleClickOpen}>
                                <MyLocationIcon sx={{ color: '#0099ff' }} />
                                <span>select your location</span>
                            </div>
                            <TextField required
                                id="postalcode"
                                label="Postal Code"
                                placeholder='Postal Code'
                                value={zip}
                                onChange={(e) => {
                                    setPostalCode(e.target.value);
                                }}
                            />
                            <TextField required
                                id="phone"
                                label="Phone"
                                placeholder='Phone'
                                type="number"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                            />
                            <TextField required
                                id="email"
                                label="Email"
                                placeholder='Email'
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                            <TextField required
                                id="username"
                                label="Username"
                                placeholder='Enter Your Username'
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                            <FormControlLabel control={<Switch
                                onChange={(e, value) => {
                                    setEnableWhatsapp(value);
                                }} />} label="Enable Whatsapp Notification" />
                        </Box>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                            {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                            Save
                        </Button>
                        <Button type="submit" variant="outlined" color="error" sx={{ textTransform: "none", width: '100%', borderRadius: '1.5rem' }} onClick={closeUpdate}>
                            {/* {load ? (<CircularProgress color="inherit" size={20} />) : ''} */}
                            Delete Account
                        </Button>
                    </CardActions>
                </form>
            </Card>
            {open ? (
                <Card sx={{ position: 'absolute', zIndex: '1', width: '45%', top: '25%', borderRadius: '25px' }}>
                    <CardContent>{GoogleMap} </CardContent>
                    <CardActions sx={{ display: 'flex', gap: '30px', justifyContent: 'center' }}>
                        <Button sx={{ textTransform: "none", color: '#ffffff', borderRadius: '1.5rem', backgroundColor: '#34b759' }} onClick={handleClose}>Confirm</Button>
                        <Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", borderRadius: '1.5rem' }}>
                            cancel
                        </Button>
                    </CardActions>

                </Card>
            ) : ''
            }
        </Box >
    );
    return (
        <>
            {isupdate ? (<Box>{editProfile}</Box>) : (<Box>{profile}</Box>)}
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}