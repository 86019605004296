import React, { useState } from 'react';
import { Typography, Card, CardContent, CardActions, Box } from "@mui/material";
import '../Styles/map.scss'

const MapMarker = ({ tooltip, $hover, lat, lng }) => {
    const infoWindow = (
        <Card sx={{ minWidth: '300px', position: 'absolute', top: '100%' }}>
            <CardContent>
                <Typography variant="h6" component="div" color="#bd3f32" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    Address Details
                </Typography>
                <Typography>
                    {tooltip}
                </Typography>
                <p style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '700' }}>
                    <span>Latitude: {lat}</span>
                    <span>Longitude: {lng}</span>
                </p>
            </CardContent>
        </Card>
    );

    return (
        <div className="map-marker">
            {infoWindow}
        </div>
    );
};


export default MapMarker;