export const environment = {
    production: false,
    apiBaseUrl:'https://www.plstest.nntx-lab.com/api/',
    apiImageUrl:'https://www.plstest.nntx-lab.com/',
    // apiBaseUrl:'https://devnodeplsbackend.nntx-lab.com/api/',
    // apiImageUrl:'https://devnodeplsbackend.nntx-lab.com/',
    // apiBaseUrl: 'http://localhost:3055/api/',
    // apiImageUrl: 'http://localhost:3055/',

    // googleAPI:'AIzaSyDuaeAGj_-CGkIcjycAGNZB1XGzccC30qs'
    googleAPI:'AIzaSyDqFjgcnveyMsME6zO_63ZgScV1_LTRVzU'
};