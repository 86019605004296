import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { useNavigate } from "react-router-dom";
import '../../Styles/details.scss'
import SellIcon from '@mui/icons-material/Sell';
import { Box, Card, CardContent, Button, TextField, Rating, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { environment } from '../../environment/environment';
import success from '../../Images/successfully-done.gif'
import axios from 'axios';
import * as All from 'braintree-web-drop-in'
import loader from '../../Images/loading.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Summary() {
    const [showdialog, setDialog] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [popup, setPoup] = useState(false);
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState('');
    const [braintreeInstance, setBraintreeInstance] = useState(undefined)
    const parkingdata = JSON.parse(localStorage.getItem('myBookingData'));
    // console.log('parkingdata data', parkingdata)
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    const [couponData, setCouponData] = useState({});

    useEffect(() => {
        getPay()
    }, []);

    const handleClickAdd = () => {
        setPoup(true);
        setDialog(false)
    };

    const handleClose = () => {
        setDialog(false)
        document.getElementById('submit-button').remove();
    };
    const handlePopup = () => {
        setPoup(false)
        localStorage.setItem('action', 'history')
        navigate('/dashboard')
    };

    const handleAdd = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                parkingId: parkingdata.parking_id,
                ratingByUser: rating,
                userId: parkingdata.userId,
                userReview: reviews,
                isReply: 1,
                replyToReviewId: 1,
                userName: localStorage.getItem('userName')
            }
            await axios.post(API_URL + 'submitReviews', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    handlePopup()
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const getPay = () => {
        var submitButton = document.querySelector('#submit-button');
        // console.log('clientToken',parkingdata.clientToken)
        All.create({
            authorization: parkingdata.clientToken,
            container: '#dropin-container',
            paypal: {
                flow: 'checkout',
                amount: 300.00,
                currency: 'EUR'
            }
        }, function (err, dropinInstance) {
            // console.log(dropinInstance)
            if (err) {
                console.log(err)
            } else {
                setBraintreeInstance(dropinInstance);
                var paymentOptions = dropinInstance.getAvailablePaymentOptions();
                // console.log('options', paymentOptions)
            }
        })
    }
    const onPaymentCompleted = async (nonceData) => {
        setLoad(true)
        // const API_URL = 'https://plstest.nntx-lab.com/api/checkoutWithPayment'
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                nonce: nonceData,
                chargeAmount: (couponData && couponData.messageCode === "101") ? parseFloat(couponData.data.total_amount).toFixed(2) : parseFloat(parkingdata.total_amount).toFixed(2) //parseFloat(parkingdata.total_amount).toFixed(2)
            }
            await axios.post(API_URL + 'checkoutWithPayment', requestBody, { headers: header }).then((response) => {
                // console.log('transaction Details', response.data.transaction)
                onSubmit(response.data.transaction)
            })
        } catch (error) {
            toast.error('error occured')
        }

    }
    const applyCoupon = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                startTime: parkingdata.start_time,
                endTime: parkingdata.end_time,
                clientId: parkingdata.userId,
                parkingId: parkingdata.parking_id,
                country: parkingdata.country,
                discountCode: coupon

            }
            await axios.post(API_URL + 'bookingAmountCalculation', requestBody, { headers: header }).then((response) => {
                if (response.data.messageCode) {
                    setCouponData(response.data)
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }
    const onSubmit = async (transactionData) => {
        console.log('transactionData', transactionData)
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                startTime: parkingdata.start_time,
                endTime: parkingdata.end_time,
                clientId: localStorage.getItem('userId'),
                parkingId: parseFloat(parkingdata.parking_id),
                bookingDate: moment.utc().format("YYYY-MM-DD[T]HH:mm:ss[.000Z]"),
                bookingStatus: "confirmed",
                vehicleRegisterNo: parkingdata.regNo,
                vehicleBrand: parkingdata.brand,
                vehicleDescription: parkingdata.vehicleDescription,
                vehicleSize: parkingdata.size,
                paymentMode: "online",
                transactionId: transactionData.id,
                paymentStatus: transactionData.status,
                paymentDate: moment.utc().format("YYYY-MM-DD[T]HH:mm:ss[.000Z]"),
                currency: "€",
                comment: "comment",
                taxRate: parkingdata.tax_rate,
                parkingAmount: parseFloat(parkingdata.parking_amount).toFixed(2),
                taxAmount: parseFloat(parkingdata.tax_amount).toFixed(2),
                discountCodeId: (couponData && couponData.messageCode === "101") ? couponData.data.discount_code_id : parkingdata.discount_code_id,
                iscountCode: (couponData && couponData.messageCode === "101") ? couponData.data.discount_code : parkingdata.discount_code,
                discountAmount: (couponData && couponData.messageCode === "101") ? couponData.data.discount_amount : parkingdata.discount_amount,
                totalAmount: (couponData && couponData.messageCode === "101") ? parseFloat(couponData.data.total_amount).toFixed(2) : parseFloat(parkingdata.total_amount).toFixed(2)
            }
            await axios.post(API_URL + 'bookParking', requestBody, { headers: header }).then((response) => {
                console.log(response.data)
                if (response.data.statusCode === 200) {
                    setDialog(true)
                    setTimeout(() => {
                        setLoad(false)
                    }, 1000);
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }
    const Loader = (
        <div className='loader'><img src={loader} loading="lazy" alt='loader' /></div>
    )

    const ratingAdd = (
        <Dialog
            open={popup}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: '300px',
                    boxShadow: '0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933',
                    border: '1px solid #dddfe2',
                    borderRadius: '8px',
                    padding: '20px',
                    alignItems: 'center'
                }
            }}>
            <DialogTitle sx={{ textAlign: 'center', color: '#bd3f32', fontSize: '1.75rem' }}>
                {"Add Your Reviews"}
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px' }}>

                <Rating
                    value={rating}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                    size="large"
                />
                <TextField
                    id="review"
                    label="Add Your Thoughts"
                    placeholder='Add Reviews'
                    value={reviews}
                    onChange={(e) => {
                        setReviews(e.target.value);
                    }}
                />

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={handlePopup} sx={{ textTransform: "none", borderRadius: '1.5rem' }}>
                    Skip
                </Button>
                <Button sx={{ textTransform: "none", color: '#ffffff', borderRadius: '1.5rem', backgroundColor: '#0099ff' }} onClick={handleAdd}>Add</Button>
            </DialogActions>
        </Dialog >
    )

    const summary = (
        <Box className="parking-summary">
            <Card className="parking-summary-card">
                <h2>Summary</h2>
                <CardContent className="parking-summary-card-item">
                    <div style={{ border: '1px solid rgb(0, 0, 0, 0.1)', borderRadius: '8px', padding: '0.5rem 1rem', marginBottom: '1rem' }}>
                        <p><span className="label">Start Date : </span>{moment(parkingdata.start_time).format("lll")}</p>
                        <p><span className="label">End Date : </span>{moment(parkingdata.end_time).format("lll")}</p>
                        <p><span className="label">Booking Date : </span>{moment().format("lll")}</p>
                        <p><span className="label">Booking Status : </span>available</p>
                    </div>
                    <div className="vehicle" style={{ borderBottom: '1px solid rgb(0, 0, 0, 0.1)', padding: '0.5rem 18px' }}>
                        <p><span className="label">Vehicle No. : </span>{parkingdata.regNo}</p>
                        <p><span className="label">Vehicle Brand : </span>{parkingdata.brand}</p>
                        <p><span className="label">Vehicle Size : </span>{parkingdata.size}</p>
                    </div>
                    <div style={{ padding: '0 18px' }}>
                        <p><span className="label">Total Time : </span>{couponData.messageCode === "101" ? couponData.data.totalTime : parkingdata.totalTime} hr</p>
                        <p><span className="label">Parking Amount : </span>€{couponData.messageCode === "101" ? couponData.data.parking_amount : parkingdata.parking_amount}</p>

                        <div className="discount-wrapper">
                            <span className="label">Apply Coupon : </span>
                            <div className="discount-coupon">
                                <div className="wrapper">
                                    <input className='wrapper-input'
                                        type="text"
                                        id="coupon"
                                        placeholder='Apply coupon here'
                                        value={coupon}
                                        onChange={(e) => {
                                            setCoupon(e.target.value);
                                        }}
                                    />
                                    <button className="apply-btn" onClick={() => applyCoupon()} >Apply</button>
                                </div>
                                {couponData.messageCode !== "101" ? (<p className="error">{couponData.message}</p>) : ('')}
                            </div>
                        </div>

                        {couponData.messageCode === "101" ? (
                            <>
                                <p>
                                    <span className="label">Coupon applied: </span><span style={{ color: '#fff', padding: '0.5rem', borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '0.5rem', backgroundColor: '#34b759' }}>
                                        <SellIcon color='#ffffff' />{couponData.data.discount_code}
                                    </span>
                                </p>
                                <p><span className="label">Discount : </span>€{couponData.data.discount_amount}</p>
                            </>
                        ) : ('')}
                        <p><span className="label">Tax : </span> <span>€{couponData.messageCode === "101" ? parseFloat(couponData.data.tax_amount).toFixed(2) : parseFloat(parkingdata.tax_amount).toFixed(2)} <span style={{ fontSize: '12px', color: 'rgba(0,0,0,0.6)', display: 'inline-block' }}>({couponData.messageCode === "101" ? couponData.data.tax_rate : parkingdata.tax_rate}%)</span></span></p>
                        <p><span className="label">Total Amount: </span>
                            <span style={{ fontSize: '1.5rem', fontWeight: '600', color: '#bd3f32' }}><span> <span style={{ fontSize: '18px', color: 'rgba(0,0,0,0.4)', display: 'inline-block', textDecoration: 'line-through' }}>€{couponData.messageCode === "101" ? parseFloat(couponData.data.parking_amount).toFixed(2): parseFloat(parkingdata.parking_amount).toFixed(2)}</span> €{couponData.messageCode === "101" ? parseFloat(couponData.data.total_amount).toFixed(2): parseFloat(parkingdata.total_amount).toFixed(2)} </span></span>
                        </p>
                    </div>
                </CardContent>
            </Card>
            <Card className="payment-section">
                <CardContent className="payment-section-item">
                    <div id="dropin-container"></div>
                    {load ? (<Box>{Loader}</Box>) : (
                        <>
                            {braintreeInstance ? (
                                <button
                                    id="submit-button"
                                    className="payment-section-item-button"
                                    onClick={() => {
                                        if (braintreeInstance) {
                                            braintreeInstance.requestPaymentMethod(
                                                (error, payload) => {
                                                    if (error) {
                                                        console.error(error);
                                                        toast.error(error)
                                                    } else {
                                                        console.log("payment method nonce", payload.nonce);
                                                        onPaymentCompleted(payload.nonce);
                                                    }
                                                });
                                        }
                                    }}
                                >Complete the Payment</button>
                            ) : ""}

                        </>
                    )}

                </CardContent>
            </Card>
            {
                showdialog ? (<div className="dialog">
                    <div className="container">
                        <img src={success} alt="img" />
                        <p>Payment Successful</p>
                        <button className="primary-btn" onClick={handleClickAdd}>Done</button>
                    </div>
                </div>) : ""
            }

            {popup ? (<>{ratingAdd}</>) : ""}
        </Box >
    );

    return (
        <>
            <Box>{summary}</Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
