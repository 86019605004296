import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Toolbar, Stack, Box, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import '../../Styles/global.scss'
import logo from '../../Images/logo1.png'
import LogOut from '../Auth/logout';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const token = localStorage.getItem('token')
export default function Header(props) {
    const menus = props.menus
    const [navItems, setNavItems] = useState(menus);
    const navigate = useNavigate();
    const show = localStorage.getItem('mainHeader')

    // useEffect(() => {
    //     window.onpopstate = () => {
    //         console.log('popstate')
    //         // setShow(true)
    //     }
    // }, []);

    const redirectToHome = () => {
        navigate("/home");
    }

    const logOut = () => {
        localStorage.clear()
        navigate('/login')
        window.location.reload(true);
    };

    const displayHeader = () => {
        return (
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack>{headerLogo}</Stack>
                <Stack>{menuItems}</Stack>
            </Toolbar>
        );
    };
    const headerLogo = (
        <div><img src={logo} loading="lazy" alt='logo' className="header-logo"onClick={redirectToHome} /></div>
    );
    const menuItems = (
        <Box className="header-link">
            {navItems.map((item) => (
                <NavLink key={item.route} to={item.link} activeclassname="active">{item.route}</NavLink>
            ))}
        </Box>
    )
    return (
        <Box sx={{ width: '100%' }}>
            {show ? (<Stack>
                <Item>{displayHeader()}</Item>
            </Stack>) : <LogOut redirectToHome={redirectToHome} logOut={logOut} />}
        </Box>
    );
}