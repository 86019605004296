import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, CardActions, Button, TextField, CircularProgress, InputLabel, FormControl, MenuItem, Select } from "@mui/material";
import axios from 'axios';
import '../../Styles/global.scss'
import { environment } from '../../environment/environment';
import park from '../../Images/parking-board.jpg'
import signup from '../../Images/signupimg.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [backend, setBackend] = useState('');
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();
    const backendPoint = [
        {
            name: 'node', id: 'node'
        },
        {
            name: 'php', id: 'php'
        }]
    const OnSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        if (backend === 'php') {
            environment.apiBaseUrl = 'https://plslaravel.netnautix-sandbox.com/api/'
            environment.apiImageUrl = 'https://plslaravel.netnautix-sandbox.com/'
        }
        else {
            environment.apiBaseUrl = 'https://www.plstest.nntx-lab.com/api/'
            environment.apiImageUrl = 'https://www.plstest.nntx-lab.com/'
        }
        const API_URL = environment.apiBaseUrl
        // console.log(environment.apiBaseUrl)
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            await axios.post(API_URL + 'login', {
                userName: username,
                password: password
            }, { headers: header }).then((response) => {
                // console.log(response.data)
                if (response.data.statusCode === 200) {
                    const authItem = {
                        route: 'Dashboard',
                        link: './dashboard'
                    }
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('userId', response.data.userId);
                    localStorage.setItem('userName', response.data.user_name);
                    props.navItem[props.navItem.length - 1] = authItem;
                    setLoad(false)
                    if (localStorage.getItem('fromSearch')) {
                        navigate("/search");
                    } else { navigate("/home"); }
                    localStorage.removeItem('fromSearch')
                    // navigate(-1);
                    // window.location.reload(true);
                } else if (response.data.statusCode === 403) {
                    toast.error(response.data.message)
                }
                else { toast.error(response.data.message) }
            })
        } catch (error) {
            toast.error('error occured')
        }
    }
    const redirectToSignup = () => {
        navigate("/signup");
    }

    const redirectToReset = () =>{
        navigate("/forgotPassword");
    }
    const loginForm = (
        <Card sx={{ height: 'fit-content' }} className="auth-card">
            <div className="auth-card-content">
                <form onSubmit={OnSubmit}>
                    <div className="auth-card-content-title">
                        {/* <img src={signin} loading="lazy" alt='login' className="auth-card-content-title-logo" /> */}
                        <h2 className="auth-card-content-title-head">Sign in
                            <span className="auth-card-content-title-head-desc">Login to manage your account</span>
                        </h2>
                    </div>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <TextField required
                                id="username"
                                label="Username"
                                placeholder='Enter Your Username'
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            // error={username.length === 0}
                            // helperText={!username.length ? 'Username is required' : ''}
                            />
                            <TextField required
                                type="password"
                                id="password"
                                label="Password"
                                placeholder='Enter Your Password'
                                value={password}
                                onChange={(pwd) => {
                                    setPassword(pwd.target.value);
                                }}
                            />
                            <FormControl>
                                <InputLabel id="backend">Select Backend</InputLabel>
                                <Select required
                                    value={backend}
                                    labelId="backend"
                                    id="backend"
                                    label="Select Backend"
                                    onChange={(item) => {
                                        setBackend(item.target.value);
                                        // localStorage.setItem('backend', item.target.value)
                                    }}
                                >
                                    {backendPoint.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                        <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                            {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                            Login
                        </Button>
                    </CardActions>
                </form>
                <div className="auth-card-content-option">
                    <p>Don't have an account?
                        {/* <span className="blue" onClick={redirectToSignup}> Sign Up </span> */}
                        <img src={signup} loading="lazy" alt='img' className='sign-up-img' onClick={redirectToSignup} />
                    </p>
                    <span onClick={redirectToReset} style={{fontSize:'14px'}}>Forgot Password</span>
                </div>
            </div>
            <div>
                <img src={park} loading="lazy" alt='img' style={{ width: '536px' }} />
            </div>

        </Card>
    );

    return (
        <>
            <Box className='auth-backgroud' sx={{ paddingTop: '100px', justifyContent: 'center' }}>
                {loginForm}
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
