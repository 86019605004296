import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../Styles/global.scss'
import { Box, Button, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment/moment';
import Address from './address'

export default function SearchLocation() {
    const [address, setAddress] = useState('');
    const [startTime, setStartDate] = useState(localStorage.getItem('startTime') !== 'null' ? moment.utc(localStorage.getItem('startTime')).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]") : null);
    const [endTime, setEndDate] = useState(localStorage.getItem('endTime') !== 'null' ? moment.utc(localStorage.getItem('endTime')).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]") : null);
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.setItem('setLocation', true)
    }, []);

    const location = (place) => {
        setAddress(place ? place : localStorage.getItem('location'));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('startTime', startTime)
        localStorage.setItem('endTime', endTime)
        navigate("/search");

    }

    const SearchBar = (
        <form onSubmit={handleSubmit}>
            <Box sx={{ padding: '16px', display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'center', boxShadow: "0 5px 10px #9aa0b90d, 0 15px 40px #a6adc933" }}>
                <div style={{ width: '500px' }}>
                    <Address myLocation={location} /></div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker sx={{ border: 'none' }}
                        renderInput={(params) => <TextField {...params} />}
                        label="Arriving Time"
                        value={startTime}
                        placeholder="Arriving Time"
                        onChange={(newStart) => {
                            setStartDate(newStart);
                        }}
                        minutesStep={30}
                        minDateTime={moment()}
                    />
                    <DateTimePicker
                        renderInput={(params) => <TextField {...params} />}
                        label="Leaving Time"
                        value={endTime}
                        placeholder="Leaving Time"
                        onChange={(newEnd) => {
                            setEndDate(newEnd);
                        }}
                        minutesStep={30}
                        minDateTime={moment()}
                    />
                </LocalizationProvider>
                <Button type="submit" variant="contained" sx={{ textTransform: "none", width: '200px', borderRadius: '1.5rem', height: '40px', backgroundColor: '#34b759' }}>Search Parking</Button>
            </Box>
        </form>
    );
    return (
        <Box>{SearchBar}</Box>
    );
}
