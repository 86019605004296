import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../../Styles/cards.scss'
import { Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, Button, Rating, TablePagination } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import { environment } from '../../environment/environment';
import loader from '../../Images/loading.gif'
import axios from 'axios';
import moment from 'moment/moment';
import EditSlot from './edit-offer-slot';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OfferedParking({ setData }) {
    const env = environment.apiImageUrl
    const [offeredParking, setOfferedParking] = useState([]);
    const [open, setOpen] = useState(false);
    const [deletedata, setDeleteData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [offerpopup, setOfferPoup] = useState(false);
    const [editdata, setEditData] = useState('')
    const [load, setLoad] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        OfferedParking()
        setLoad(true)
    }, []);

    const OfferedParking = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: localStorage.getItem('userId')
            }
            await axios.post(API_URL + 'getTimeSlotByUserId', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    setTimeout(() => {
                        setLoad(false)
                    }, 1000);
                    setOfferedParking(response.data.response)
                } else {toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleClickOpen = (data) => {
        setOpen(true);
        setDeleteData(data)
    };

    const handleClickOffer = (data) => {
        setOfferPoup(true);
        setEditData(data)
    };

    const handleClose = () => {
        setOpen(false);
        setOfferPoup(false); 
    };

    const handleDelete = async (e) => {
        // console.log('deletedata', deletedata)
        e.preventDefault();
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                parkingId: deletedata.id,
                userId: localStorage.getItem('userId'),
                startTime: deletedata.start_time,
                endTime: deletedata.end_time,
                status: deletedata.active_status ? "available" : "unavailable",
                parkingHourlyRate: deletedata.parking_price,
                action: 'delete',
                oldStartTime: deletedata.start_time,
                oldEndTime: deletedata.end_time
            }
            await axios.post(API_URL + 'editOfferedParking', requestBody, { headers: header }).then((response) => {
                // console.log(response.data)
                if (response.data) {
                    handleClose()
                    OfferedParking()
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const pagination = (
        <TablePagination
            rowsPerPageOptions={[3, 6, 9, 12]}
            component="div"
            count={offeredParking ? offeredParking.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )

    const Loader = (
        <div className='loader'><img src={loader} loading="lazy" alt='loader' /></div>
    )

    const OfferedParkingData = (
        <>
            <Box className="cards" sx={{ width: '100%' }}>
                <h2 className="head">Offered Parking</h2>
                {load ? (<Box>{Loader}</Box>) : (
                    <>
                        {offeredParking && offeredParking.length !== 0 ? (
                            <>
                                <div>{pagination}</div>
                                {offeredParking
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <Card key={index} className="cards-item">
                                            <CardContent className="cards-item-contents">
                                                <div className="gallery">
                                                    {item.user_parking_images.length !== 0 ? (
                                                        <img src={env + item.user_parking_images[0].imageURL} loading="lazy" alt='uploaded' className='gallery-img' />
                                                    ) : (
                                                        <div className="default-img">
                                                            <p>No Image Uploaded</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="details">
                                                    <p className="name">{item.parking_name}
                                                        {/* <span className="avg" style={{ backgroundColor: item.averageRating > 3 ? "rgb(38, 126, 62)" : "#bd3f32" }}>
                                            {item.averageRating}
                                        </span> */}
                                                        <Rating name="read-only" value={item.averageRating} readOnly />
                                                    </p>
                                                    <span className="address"><span className="label">Address: </span>{item.address}</span>
                                                    <div className="location">
                                                        <span><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}, {item.country}</span>
                                                    </div>
                                                    <div className="booking-time">
                                                        <span className="time"> <span className="label">Start-Time: </span>{moment(item.start_time).format("lll")}</span>
                                                        <span className="time"><span className="label">End-Time: </span>{moment(item.end_time).format("lll")}</span>
                                                    </div>
                                                </div>
                                                <div className="actions">
                                                    <DeleteIcon sx={{ color: '#bd3f32' }} onClick={() => handleClickOpen(item)} />
                                                    <span className="euro"><span>€{item.parking_price}</span><sub>/hr</sub> <span className='subinfo'>(prices may vary)</span> </span>
                                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                        {item.isUpdatable ? (
                                                            <button className="primary-btn green-btn" onClick={() => handleClickOffer(item)}> Edit</button>
                                                        ) : (<Button variant="contained" sx={{ padding: "12px 24px", borderRadius: '1.5rem', width: '100px', textTransform: "none" }} disabled>Edit</Button>
                                                        )
                                                        }

                                                    </div>

                                                </div>
                                            </CardContent>
                                        </Card >
                                    ))
                                }
                            </>
                        ) : (
                            <div className="no-parking-found">
                                <p>No parking found</p>
                            </div>
                        )}
                    </>
                )}
            </Box >

            <Dialog
                open={open}
                onClose={handleClose}>
                {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Subscribe
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText>
                        Are you sure, you want to delete this parking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>

    )

    const offerParking = (
        <Dialog sx={{
            "& .MuiDialog-paper": {
                minWidth: '450px'
            }
        }}
            open={offerpopup}
            onClose={handleClose}>
            < EditSlot editdata={editdata} close={handleClose} getdata={OfferedParking} />
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Skip
                </Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <>
            <Box sx={{ width: '100%' }}> {OfferedParkingData}</Box>
            <Box>{offerParking}</Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
