import React, { useState } from 'react';
import AddressAutocomplete from 'mui-address-autocomplete';
import { TextField } from "@mui/material";
import Geocode from "react-geocode";
import { environment } from '../environment/environment';

export default function Address({ myLocation }) {
  const [location, setLocation] = useState(localStorage.getItem('setLocation') ? localStorage.getItem('location') : null);
  const googleAPI = environment.googleAPI
  const position = (address) => {
    Geocode.setApiKey(googleAPI)
    Geocode.fromAddress(address).then(
      (response) => {
        let lat = response.results[0].geometry.location.lat
        let lng = response.results[0].geometry.location.lng
        console.log(lat, lng);
        localStorage.setItem('latitude', lat)
        localStorage.setItem('longitude', lng)
      },
      (error) => {
        console.error(error);
      }
    );
  }
  return (
    <AddressAutocomplete required
      apiKey={googleAPI}
      label="Location"
      fields={['geometry']}
      value={location}
      onChange={(_, location) => {
        setLocation(location);
        // console.log('location',location.formatted_address)
        localStorage.setItem('location', location.formatted_address)
        myLocation(location.formatted_address)
        position(location.formatted_address)
      }}
      renderInput={(params) =>
        <TextField {...params} label="Select Location"
          // required={localStorage.getItem('startTime') && localStorage.getItem('endTime')}
        />}
      disableClearable
    />
  );
}
