import { useLocation } from 'react-router-dom';

const Main = ({ children }) => {
  const location = useLocation();
  // console.log('current route', location.pathname);
  if (location.pathname === '/dashboard') {
    localStorage.removeItem('mainHeader')
    localStorage.removeItem('setLocation')
  } else { localStorage.setItem('mainHeader', true) }
  return children;
};

export default Main;