import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, CardActions, Button, TextField, Switch, FormControlLabel, CircularProgress,InputLabel, FormControl,MenuItem, Select } from "@mui/material";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GoogleMapReact from 'google-map-react';
import AddressAutocomplete from 'mui-address-autocomplete';
import Geocode from "react-geocode";
import axios from 'axios';
import '../../Styles/global.scss'
import { environment } from '../../environment/environment';
import park from '../../Images/valet-parking.jpg'
import MapMarker from '../../helpers/location-map-marker'
import { getMapOptions } from '../../helpers/map-options'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Signup() {
    const googleAPI = environment.googleAPI
    const [open, setOpen] = useState(false);
    // const [validEmail, setValidEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true); //change this
    const [fullname, setFullName] = useState('');
    const [zip, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState(null);
    const [enableWhatsapp, setEnableWhatsapp] = useState(false)
    const [backend, setBackend] = useState('');
    const [load, setLoad] = useState(false);
    const [latitude, setLat] = useState('')
    const [longitude, setLng] = useState('')
    const [center] = useState({ lat: parseFloat(localStorage.getItem('currentLat')), lng: parseFloat(localStorage.getItem('currentLng')) });
    const [zoom] = useState(7);
    const [maplat, setMapLat] = useState(parseFloat(localStorage.getItem('currentLat')))
    const [maplng, setMapLng] = useState(parseFloat(localStorage.getItem('currentLng')))
    const navigate = useNavigate();

    const backendPoint = [
        {
            name: 'node', id: 'node'
        },
        {
            name: 'php', id: 'php'
        }]

    const position = (address) => {
        Geocode.setApiKey(googleAPI)
        Geocode.fromAddress(address).then(
            (response) => {
                setLat(response.results[0].geometry.location.lat)
                setLng(response.results[0].geometry.location.lng)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const getAddress = (lat, lng) => {
        setLat(lat)
        setLng(lng)
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setAddress(response.results[0].formatted_address)
            },
        );
    }

    // console.log(latitude, longitude, address);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMapLat(parseFloat(localStorage.getItem('currentLat')))
        setMapLng(parseFloat(localStorage.getItem('currentLng')))
    };

    const checkEmailValidation = async (id) => {
        if (backend === 'php') {
            environment.apiBaseUrl = 'https://plslaravel.netnautix-sandbox.com/api/'
            environment.apiImageUrl = 'https://plslaravel.netnautix-sandbox.com/'
        }
        else {
            environment.apiBaseUrl = 'https://www.plstest.nntx-lab.com/api/'
            environment.apiImageUrl = 'https://www.plstest.nntx-lab.com/'
        }
        const API_URL = environment.apiBaseUrl
        const header = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                emailId: id,
            }
            await axios.post(API_URL + 'emailValidation', requestBody, { headers: header }).then((response) => {
                console.log(response.data)
                if (response.data.statusCode === 200) {
                    setValidEmail(true)
                } else {
                    setValidEmail(false)
                    toast.error(response.data.message)
                }
            })
        } catch (error) {
            toast.error('error occured')
        }
    }

    const OnSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        // checkEmailValidation(email)
        if (backend === 'php') {
            environment.apiBaseUrl = 'https://plslaravel.netnautix-sandbox.com/api/'
            environment.apiImageUrl = 'https://plslaravel.netnautix-sandbox.com/'
        }
        else {
            environment.apiBaseUrl = 'https://www.plstest.nntx-lab.com/api/'
            environment.apiImageUrl = 'https://www.plstest.nntx-lab.com/'
        }
        const API_URL = environment.apiBaseUrl
        if (validEmail) {
            try {
                let requestBody = {
                    fullName: fullname,
                    fullAddress: address,
                    phoneNumber: phone,
                    postalCode: zip,
                    emailId: email,
                    userName: username,
                    password: password,
                    whatsappNotification: enableWhatsapp,
                    latitude: latitude,
                    longitude: longitude
                }
                await axios.post(API_URL + 'userRegistration', requestBody).then((response) => {
                    console.log(response.data)
                    if (response.data.statusCode === 200) {
                        clearStorage()
                        setLoad(false)
                        navigate("/login");
                    } else { toast.error(response.data.message) }
                })
            } catch (error) {
                alert('error occured')
            }
        } else { toast.error('Please enter valid email id') }
    }
    const redirectToLogin = () => {
        navigate("/login");
    }
    const clearStorage = () => {
        localStorage.removeItem('latitude')
        localStorage.removeItem('longitude')
    }
    const GoogleMap = (
        <Card>
            <div style={{ height: '500px' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: environment.googleAPI }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={getMapOptions}
                    onClick={ev => {
                        setMapLat(ev.lat)
                        setMapLng(ev.lng)
                        getAddress(ev.lat, ev.lng)
                        // console.log("latitide = ", ev.lat,ev.lng);
                    }}
                >
                    <MapMarker
                        position={{ lat: maplat, lng: maplng }}
                        tooltip={address ? address : localStorage.getItem('currentAddress')}
                        lat={maplat}
                        lng={maplng}
                    >
                    </MapMarker>
                </GoogleMapReact>
            </div>
        </Card >
    );
    const SignupForm = (
        <>
            <Card sx={{ minWidth: 1200, height: 'fit-content' }} style={{ filter: open ? 'brightness(0.4)' : 'brightness(1)' }} className="auth-card">
                <div className="auth-card-content">
                    <form onSubmit={OnSubmit}>
                        <div className="auth-card-content-title">
                            {/* <img src={signin} loading="lazy" alt='signup' className="auth-card-content-title-logo" /> */}
                            <h2 className="auth-card-content-title-head">Sign in
                                <span className="auth-card-content-title-head-desc">Create your account</span>
                            </h2>
                        </div>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                <TextField required
                                    id="fullname"
                                    label="Full Name"
                                    placeholder='Full Name'
                                    value={fullname}
                                    onChange={(e) => {
                                        setFullName(e.target.value);
                                    }}
                                />
                                <AddressAutocomplete required
                                    apiKey={googleAPI}
                                    label="Location"
                                    fields={['geometry']}
                                    value={address}
                                    onChange={(_, location) => {
                                        // setAddress(location);
                                        setAddress(location.formatted_address)
                                        position(location.formatted_address)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Location" required />}
                                    disableClearable
                                />

                                <div className="map-location" onClick={handleClickOpen}>
                                    <MyLocationIcon sx={{ color: '#0099ff' }} />
                                    <span>select your location</span>
                                </div>
                                <TextField required
                                    id="postalcode"
                                    label="Postal Code"
                                    placeholder='Postal Code'
                                    value={zip}
                                    onChange={(e) => {
                                        setPostalCode(e.target.value);
                                    }}
                                />
                                <TextField required
                                    id="phone"
                                    label="Phone"
                                    placeholder='Phone'
                                    type="number"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                    }}
                                />
                                <TextField required
                                    id="email"
                                    label="Email"
                                    placeholder='Email'
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                                <TextField required
                                    id="username"
                                    label="Username"
                                    placeholder='Enter Your Username'
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                                <TextField required
                                    type="password"
                                    id="password"
                                    label="Password"
                                    placeholder='Enter Your Password'
                                    value={password}
                                    onChange={(pwd) => {
                                        setPassword(pwd.target.value);
                                    }}
                                />
                                <FormControl>
                                    <InputLabel id="backend">Select Backend</InputLabel>
                                    <Select required
                                        value={backend}
                                        labelId="backend"
                                        id="backend"
                                        label="Select Backend"
                                        onChange={(item) => {
                                            setBackend(item.target.value);
                                            // localStorage.setItem('backend', item.target.value)
                                        }}
                                    >
                                        {backendPoint.map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControlLabel control={<Switch
                                    onChange={(e, value) => {
                                        setEnableWhatsapp(value);
                                    }} />} label="Enable Whatsapp Notification" />
                            </Box>
                        </CardContent>
                        <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                                {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                                signup
                            </Button>
                        </CardActions>
                    </form>
                    <div className="auth-card-content-option">
                        <p>Alreay have an account?<span className="blue" onClick={redirectToLogin}> Sign in </span></p>
                    </div>
                </div>
                <div>
                    <img src={park} loading="lazy" alt='img' style={{ width: '630px', height: '750px' }} />
                </div>
            </Card >
            {open ? (
                <Card sx={{ position: 'absolute', zIndex: '1', width: '45%', top: '25%', borderRadius: '25px' }}>
                    <CardContent>{GoogleMap} </CardContent>
                    <CardActions sx={{ display: 'flex', gap: '30px', justifyContent: 'center' }}>
                        <Button sx={{ textTransform: "none", color: '#ffffff', borderRadius: '1.5rem', backgroundColor: '#34b759' }} onClick={handleClose}>Confirm</Button>
                        <Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", borderRadius: '1.5rem' }}>
                            cancel
                        </Button>
                    </CardActions>

                </Card>
            ) : ''
            }
        </>

    );

    return (
        <>
            <Box className='auth-backgroud' sx={{ padding: '100px 0 200px 0', justifyContent: 'center' }}>
                {SignupForm}
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
