import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../../Styles/cards.scss'
import { Box, Card, CardContent, Typography, Dialog, DialogActions, DialogContent, DialogContentText, TablePagination, DialogTitle, Paper, PaperProps, CircularProgress, Button, Rating } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import { environment } from '../../environment/environment';
import loader from '../../Images/loading.gif'
import axios from 'axios';
import moment from 'moment/moment';

export default function MyParking({ setData }) {
    const env = environment.apiImageUrl
    const [myParkingParking, setMyParking] = useState([]);
    const [open, setOpen] = useState(false);
    const [deletedata, setDeleteData] = useState([]);
    const [load, setLoad] = useState(true);
    const [btnload, setbtnLoad] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        OnMyParking()
        setLoad(true)
    }, []);

    const OnMyParking = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: localStorage.getItem('userId')
            }
            await axios.post(API_URL + 'getMyParkings', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    setTimeout(() => {
                        setLoad(false)
                    }, 1000);
                    setMyParking(response.data.parkings)
                } else { alert(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleClickOpen = (data) => {
        setOpen(true);
        setDeleteData(data)
    };

    const handleClose = () => {
        setOpen(false);
    };


    const redirectToDetails = (item) => {
        localStorage.setItem('myData', JSON.stringify(item));
        localStorage.setItem('fromMyParking', true);
        localStorage.removeItem('fromHistroy')
        navigate('/details')
    }

    const handleDelete = async (e) => {
        // console.log(deletedata)
        e.preventDefault();
        setbtnLoad(true);
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': '*',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            const formData = new FormData();
            formData.append('parkingId', deletedata.id);
            formData.append('parkingname', deletedata.parking_name);
            formData.append('size', deletedata.size);
            formData.append('type', deletedata.type);
            formData.append('buildingNo', deletedata.building_no);
            formData.append('address1', deletedata.address);
            formData.append('latitude', deletedata.latitude);
            formData.append('longitude', deletedata.longitude);
            formData.append('nearestlandmark', deletedata.nearest_mark);
            formData.append('city', deletedata.city);
            formData.append('country', deletedata.country);
            formData.append('zip', deletedata.postal_code);
            formData.append('slot_width', deletedata.slot_width);
            formData.append('slot_length', deletedata.slot_length);
            formData.append('slot_height', deletedata.slot_height);
            formData.append('parking_price', deletedata.parking_price);
            formData.append('parkingDescription', deletedata.parking_description);
            formData.append('hasAccessLock', deletedata.has_access_lock);
            formData.append('accessInformation', deletedata.access_information);
            formData.append('userid', deletedata.user_id);
            formData.append('slotNo', deletedata.slot_no);
            formData.append('action', 'delete');
            await axios.post(API_URL + 'parkingRegistration', formData, { headers: header }).then((response) => {
                // console.log(response.data)
                if (response.data.statusCode === 200) {
                    handleClose()
                    OnMyParking()
                    setbtnLoad(false);
                } else { 
                    setbtnLoad(false);
                    alert(response.data.message)
                 }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const pagination = (
        <TablePagination
            rowsPerPageOptions={[3, 6, 9, 12]}
            component="div"
            count={myParkingParking.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )

    const Loader = (
        <div className='loader'><img src={loader} loading="lazy" alt='loader' /></div>
    )

    const MyParkingedData = (
        <>
            <Box className="cards" sx={{ width: '100%' }}>
                <h2 className="head">My Parking</h2>
                {load ? (<Box>{Loader}</Box>) : (
                    <>
                        {myParkingParking.length !== 0 ? (
                            <>
                                <div>{pagination}</div>
                                {myParkingParking
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item) => (
                                        <Card key={item.id} className="cards-item">
                                            <CardContent className="cards-item-contents">
                                                <div className="gallery">
                                                    {item.user_parking_images.length !== 0 ? (
                                                        <img src={env + item.user_parking_images[0].imageURL} loading="lazy" alt='uploaded' className='gallery-img' />
                                                    ) : (
                                                        <div className="default-img">
                                                            <p>No Image Uploaded</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="details">
                                                    <p className="name">{item.parking_name}
                                                        {/* <span className="avg" style={{ backgroundColor: item.averageRating > 3 ? "rgb(38, 126, 62)" : "#bd3f32" }}>
                                        {item.averageRating}
                                    </span> */}
                                                        <Rating name="read-only" value={item.averageRating} readOnly />
                                                    </p>
                                                    <span className="address"><span className="label">Address: </span>{item.address}</span>
                                                    <div className="location">
                                                        <span><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}, {item.country}</span>
                                                    </div>
                                                    <div className="features">
                                                        {item.featureList.map((feature, index) => (
                                                            <span key={index}>{feature.feature_name} </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="actions">
                                                    <DeleteIcon sx={{ color: '#bd3f32' }} onClick={() => handleClickOpen(item)} />
                                                    <span className="euro"><span>€{item.parking_price}</span><sub>/hr</sub> <span className='subinfo'>(prices may vary)</span> </span>
                                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                        <button className="primary-btn" onClick={() => redirectToDetails(item)}>Details</button>
                                                        <button className="primary-btn green-btn" onClick={() => setData(item)}>Edit</button>
                                                    </div>

                                                </div>
                                            </CardContent>
                                        </Card >
                                    ))
                                }
                            </>
                        ) : (
                            <div className="no-parking-found">
                                <p>No parking found</p>
                            </div>
                        )}
                    </>
                )}

            </Box >

            <Dialog
                open={open}
                onClose={handleClose}>
                {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Subscribe
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText>
                        Are you sure, you want to delete this parking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={btnload}>
                        {btnload ? (<CircularProgress color="inherit" size={20} />) : ''}
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
    return (
        <Box sx={{ width: '100%' }}> {MyParkingedData}</Box>
    );
}
