import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, CardActions, Button, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment/moment';
import Address from '../../helpers/address';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Home(props) {
    const [address, setAddress] = useState('');
    const [startTime, setStartDate] = useState(null);
    const [endTime, setEndDate] = useState(null);
    const navigate = useNavigate();
    localStorage.removeItem('setLocation')

    useEffect(() => {
        clearStorage()
    });

    const location = (place) => {
        setAddress(place);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('address', address, moment.utc(startTime).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]"), moment.utc(endTime).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]"))
        // if (address) {
        localStorage.setItem('startTime', startTime)
        localStorage.setItem('endTime', endTime)
        if (((startTime && endTime) && (startTime < endTime)) || (!startTime && !endTime)) {
            if (!address) { localStorage.setItem('location', localStorage.getItem('currentAddress')) }
            localStorage.setItem('setLocation', true)
            navigate("/search");
        } else { toast.error('End date must be greater than start date.') }

        // } else { alert('Please select location') }
    }
    const redirectToSearch = () => {
        localStorage.setItem('startTime', "null")
        localStorage.setItem('endTime', "null")
        localStorage.setItem('setLocation', true)
        localStorage.setItem('location', localStorage.getItem('currentAddress'))
        navigate("/search");
    }
    const clearStorage = () => {
        // localStorage.removeItem('location')
        localStorage.removeItem('latitude')
        localStorage.removeItem('longitude')
        localStorage.removeItem('startTime')
        localStorage.removeItem('endTime')
        localStorage.removeItem('setLocation')
    }
    const welcomeForm = (
        <Card sx={{ minWidth: 360, height: 'fit-content' }} className="form-card">
            <form onSubmit={handleSubmit}>
                <CardContent className="form-card-content">
                    <Typography gutterBottom variant="h5" color='#bd3f32' sx={{ fontWeight: '600', textAlign: 'center' }}>
                        Select Location
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                        <Address myLocation={location} />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker sx={{ border: 'none' }}
                                renderInput={(params) => <TextField {...params} />}
                                label="Arriving Time"
                                value={startTime}
                                placeholder="Arriving Time"
                                onChange={(newStart) => {
                                    setStartDate(newStart);
                                }}
                                minutesStep={30}
                                minDateTime={moment()}
                            />
                            <DateTimePicker
                                renderInput={(params) => <TextField {...params} />}
                                label="Leaving Time"
                                value={endTime}
                                placeholder="Leaving Time"
                                onChange={(newEnd) => {
                                    setEndDate(newEnd);
                                }}
                                minutesStep={30}
                                minDateTime={moment()}
                            />
                        </LocalizationProvider>
                    </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                    <Button type="submit" variant="contained" sx={{ textTransform: "none", width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>Search</Button>
                    <Button variant="outlined" color="error" sx={{ textTransform: "none", width: '100%', borderRadius: '1.5rem' }} onClick={redirectToSearch}>Search Parking Near Me</Button>
                </CardActions>
            </form>
        </Card>
    );
    return (
        <>
            <Box className='backgroud'>
                <div className="backgroud-intro">
                    <h1 className="backgroud-intro-title">One Platform For Your <span className="backgroud-intro-title blue">Parking lot sharing
                    </span>Needs</h1>
                    <p className="backgroud-intro-desc">Enhance your parking management and drive value with visibility, control and
                        flexibility.</p>
                </div>
                {welcomeForm}
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}