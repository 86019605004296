import React, { useState } from 'react';
import { Typography, Card, CardContent, CardActions } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
// import marker from '../Images/home/marker3.png'
import '../Styles/map.scss'

const Marker = ({ tooltip, $hover, lat, lng }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>

      <div className={$hover ? "marker hover" : "marker"} onClick={() => handleClickOpen()}>
        {/* <img src={marker} loading="lazy" alt='location' className="marker" /> */}
        <span className="marker-text" title={tooltip}></span>
      </div>
      {open ? (
        <Card sx={{ maxWidth: '300px' }}>
          <CardContent>
            <Typography variant="h6" component="div" color="#bd3f32" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between' }}>
              Address Details
              <CancelIcon sx={{ color: '#bd3f32' }} onClick={() => handleClose()} />
            </Typography>
            <Typography>
              {tooltip}
            </Typography>
            <p style={{ display: 'flex', justifyContent: 'space-between', fontWeight:'700' }}>
              <span>Latitude: {lat}</span>
              <span>Longitude: {lng}</span>
            </p>
          </CardContent>
        </Card>) : ('')}
    </>
  );
};

export default Marker;