import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, CardActions, Button, TextField, CircularProgress } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment/moment';
import axios from 'axios';
import '../../Styles/cards.scss'
import { environment } from '../../environment/environment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditSlot(props) {
    const [load, setLoad] = useState(false);
    const [startOfferTime, setStartOfferDate] = useState(props.editdata.start_time);
    const [endOfferTime, setEndOfferDate] = useState(props.editdata.end_time);
    const [rate, setRate] = useState(props.editdata.parking_price);
    const navigate = useNavigate();

    // console.log('editdata', props.editdata)

    const OnSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        if (moment(endOfferTime).isAfter(moment(startOfferTime))) {
            const API_URL = environment.apiBaseUrl
            const token = localStorage.getItem('token')
            const header = {
                "Authorization": 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            try {
                let requestBody = {
                    parkingId: props.editdata.id,
                    userId: props.editdata.user_id,
                    startTime: startOfferTime,
                    endTime: endOfferTime,
                    status: props.editdata.active_status ? "available" : "unavailable",
                    parkingHourlyRate: rate,
                    action: "update",
                    oldStartTime: props.editdata.start_time,
                    oldEndTime: props.editdata.end_time
                }
                await axios.post(API_URL + 'editOfferedParking', requestBody, { headers: header }).then((response) => {
                    if (response.data.statusCode === 200) {
                        setLoad(false)
                        { props.close() }
                        { props.getdata() }
                    } else { toast.error(response.data.message) }
                })
            } catch (error) {
                if (error.response?.data?.statusCode === 498) {
                    navigate('/login')
                }
            }
        } else { toast.error('End date must be greater than start date.') }
    }

    const EditSlotForm = (
        <Card className='register-card'>
            <h2 className="register-card-head">Update Slot</h2>
            <form onSubmit={OnSubmit}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px', maxWidth: '700px' }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker sx={{ border: 'none' }}
                                renderInput={(params) => <TextField {...params} />}
                                label="Arriving Time"
                                value={startOfferTime}
                                placeholder="Arriving Time"
                                onChange={(newStart) => {
                                    setStartOfferDate(newStart);
                                }}
                                minutesStep={30}
                                minDateTime={moment()}
                            />
                            <DateTimePicker
                                renderInput={(params) => <TextField {...params} />}
                                label="Leaving Time"
                                value={endOfferTime}
                                placeholder="Leaving Time"
                                onChange={(newEnd) => {
                                    setEndOfferDate(newEnd);
                                }}
                                minutesStep={30}
                                minDateTime={moment()}
                            />
                        </LocalizationProvider>
                        <TextField required
                            id="rate"
                            label="Parking Rate"
                            placeholder="Parking Rate"
                            value={rate}
                            onChange={(e) => {
                                setRate(e.target.value);
                            }}
                        />
                    </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                        {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                        Update Slot
                    </Button>
                </CardActions>
            </form>
        </Card>
    );

    return (
        <>
            <Box sx={{ maxWidth: '730px' }}>
                {EditSlotForm}
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
