import React, { useState } from 'react';
import { useNavigate,useParams } from "react-router-dom";
import { Box, Card, CardContent, CardActions, Button, TextField, CircularProgress, InputLabel, FormControl, MenuItem, Select } from "@mui/material";
import axios from 'axios';
import '../../Styles/global.scss'
import { environment } from '../../environment/environment';
import park from '../../Images/parking-board.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [load, setLoad] = useState(false);
    const [backend, setBackend] = useState('');
    const backendPoint = [
        // {
        //     name: 'node', id: 'node'
        // },
        {
            name: 'php', id: 'php'
        }]

    const navigate = useNavigate();
    const searchParams = useParams()
    // console.log(searchParams)

    const OnSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        if (backend === 'php') {
            environment.apiBaseUrl = 'https://plslaravel.netnautix-sandbox.com/api/'
            environment.apiImageUrl = 'https://plslaravel.netnautix-sandbox.com/'
        }
        else {
            environment.apiBaseUrl = 'https://www.plstest.nntx-lab.com/api/'
            environment.apiImageUrl = 'https://www.plstest.nntx-lab.com/'
        }
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            await axios.post(API_URL + 'reset-password', {
                user_id: searchParams.user_id,
                password: password,
                token: searchParams.token
            }, { headers: header }).then((response) => {
                setLoad(false)
                navigate("/login");
            })
        } catch (error) {
            toast.error('error occured')
        }
    }

    const passwordForm = (
        <Card sx={{ height: 'fit-content' }} className="auth-card">
            <div className="auth-card-content">
                <form onSubmit={OnSubmit}>
                    <div className="auth-card-content-title">
                        <h2 className="auth-card-content-title-head">Reset Password
                            <span className="auth-card-content-title-head-desc">Reset your password</span>
                        </h2>
                    </div>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <TextField required
                                id="email"
                                label="Email"
                                placeholder='Enter Your Email'
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />

                            <TextField required
                                type="password"
                                id="password"
                                label="Password"
                                placeholder='Enter Your Password'
                                value={password}
                                onChange={(pwd) => {
                                    setPassword(pwd.target.value);
                                }}
                            />

                            <FormControl>
                                <InputLabel id="backend">Select Backend</InputLabel>
                                <Select required
                                    value={backend}
                                    labelId="backend"
                                    id="backend"
                                    label="Select Backend"
                                    onChange={(item) => {
                                        setBackend(item.target.value);
                                        // localStorage.setItem('backend', item.target.value)
                                    }}
                                >
                                    {backendPoint.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                        <Button type="submit" variant="contained" sx={{ textTransform: "none", display: 'flex', gap: '20px', width: '100%', borderRadius: '1.5rem', backgroundColor: '#0099ff' }}>
                            {load ? (<CircularProgress color="inherit" size={20} />) : ''}
                            Submit
                        </Button>
                    </CardActions>
                </form>
            </div>
            <div>
                <img src={park} loading="lazy" alt='img' style={{ width: '536px' }} />
            </div>

        </Card>
    );

    return (
        <>
            <Box className='auth-backgroud' sx={{ paddingTop: '100px', justifyContent: 'center' }}>
                {passwordForm}
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
