import React from 'react';
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, Rating, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from "@mui/material";
import GoogleMap from '../../helpers/google-map';
import { environment } from '../../environment/environment';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchLocation from '../../helpers/search-location';
import PlaceIcon from '@mui/icons-material/Place';
import moment from 'moment/moment';
import axios from 'axios';
import '../../Styles/details.scss';
import BookParking from './book-parking';
import OfferSlot from './offer-slot';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export default function Details() {
    const [allReviews, setAllReviews] = useState([]);
    const [allFeatures, setAllFeatures] = useState([]);
    const [open, setOpen] = useState(false);
    const [deletedata, setDeleteData] = useState([]);
    const [popup, setPoup] = useState(false);
    const [editdata, setEditData] = useState([]);
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState('');
    const [bookpopup, setBookPoup] = useState(false);
    const [offerpopup, setOfferPoup] = useState(false);
    const [bookdata, setBookData] = useState([]);
    const env = environment.apiImageUrl
    const navigate = useNavigate();
    const data = JSON.parse(localStorage.getItem('myData'));
    // console.log('data', data)

    const handleClickOpen = (data) => {
        setOpen(true);
        setDeleteData(data)
    };
    const handleClickUpdate = (data) => {
        setPoup(true);
        setEditData(data)
    };
    const handleClickBook = (data) => {
        setBookPoup(true);
        setBookData(data)
    };
    const handleClickOffer = () => {
        setOfferPoup(true);
    };

    const goToHistory = () => {
        localStorage.setItem('action', 'history')
        navigate('/dashboard')
    };

    const handleClose = () => {
        setOpen(false);
        setPoup(false);
        setBookPoup(false);
        setOfferPoup(false);
    };

    useEffect(() => {
        GetReviews()
        GetFeatures()
        window.onpopstate = () => {
            console.log('details popstate')
            localStorage.removeItem('fromHistroy')
            localStorage.removeItem('fromMyParking')
        }
    }, []);

    const GetReviews = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: data.user_id ? data.user_id : parseFloat(localStorage.getItem('userId')),
                parkingId: data.parking_id ? data.parking_id : data.id
            }
            await axios.post(API_URL + 'getReviewsByParkingID', requestBody, { headers: header }).then((response) => {
                // console.log(response.data.userData)
                if (response.data.status === 200) {
                    setAllReviews(response.data.userData)
                } else { toast.info(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const GetFeatures = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                parkingId: data.id
            }
            await axios.post(API_URL + 'getParkingFeatureById', requestBody, { headers: header }).then((response) => {
                // console.log(response.data.featuresList)
                if (response.data.statusCode === 200) {
                    setAllFeatures(response.data.featuresList)
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }
    const handleDelete = async (e) => {
        console.log('deletedata', deletedata)
        e.preventDefault();
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                id: deletedata.id,
            }
            await axios.post(API_URL + 'deleteReviews', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    handleClose()
                    GetReviews()
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const handleUpdate = async (e) => {
        console.log('editdata', editdata)
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                id: editdata.id,
                parkingId: editdata.parking_id,
                ratingByUser: rating ? rating : editdata.rating_by_user,
                userId: editdata.user_id,
                userReview: reviews ? reviews : editdata.review
            }
            await axios.post(API_URL + 'updateReviews', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    handleClose()
                    GetReviews()
                } else { toast.error(response.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }

    const Search = (
        <SearchLocation />
    );

    const mapDetails = (
        <>
            <Box className='my-details'>
                {data.user_parking_images.length !== 0 ?
                    <Card sx={{ width: '53%', borderRadius:'10px' }}>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {data.user_parking_images.map((img, index) => (
                                <SwiperSlide  key={index}>
                                    <span>
                                    <img src={env + img.imageURL} loading="lazy" alt='uploaded' height='100%' width='100%' />
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Card>
                    :
                    (<div className="my-details-default">
                        <p>No Image Uploaded</p>
                    </div>)}

                <Card sx={{ flexBasis: '55%' }}><GoogleMap /></Card>
            </Box >


        </>
    );
    const parkingDetails = (
        <>
            <Box className='parking-details'>
                {data ?
                    (
                        <div className="parking-details-info">
                            <h2>{data.parking_name}
                                <Rating name="read-only" value={data.averageRating} readOnly />
                            </h2>
                            <p className="parking-desc">
                                {data.parking_description}</p>
                            <p className="head"><span className="label">Address: </span>{data.address}</p>
                            <p className="head"><span className="label">Landmark: </span>{data.nearest_mark === "null" ? '-' : data.nearest_mark} </p>

                            {localStorage.getItem('fromMyParking') ? '' : (
                                <>
                                    <p className="head"> <span className="label">Start-Time: </span>{moment(localStorage.getItem('fromHistroy') ? data.booking_start_time : data.start_time).format("lll")}</p>
                                    <p className="head"><span className="label">End-Time: </span>{moment(localStorage.getItem('fromHistroy') ? data.booking_end_time : data.end_time).format("lll")}</p>
                                </>)}

                            <div className="location">
                                <span><PlaceIcon sx={{ fontSize: 'small' }} />{data.city}, {data.country}</span>
                            </div>
                        </div>
                    ) :
                    (<div>
                        <p>No Details Available</p>
                    </div>)
                }
                <div className="parking-details-reviews">
                    <h3>Reviews</h3>
                    {allReviews.length !== 0 ?
                        (<div>
                            {allReviews.map((item) => (
                                <div key={item.id} className="review">
                                    <div className="user-detail">
                                        <div className="user-wrapper">
                                            <AccountCircleRoundedIcon />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span className="name">{item.user_name}</span>
                                                <span className="review-time">{moment(item.created_date).format("lll")}</span>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            {item.user_id == localStorage.getItem('userId') ? (
                                                <> <EditIcon sx={{ color: '#09f' }} onClick={() => handleClickUpdate(item)} />
                                                    <DeleteIcon sx={{ color: '#bd3f32' }} onClick={() => handleClickOpen(item)} /></>
                                            ) : ('')}
                                            <Rating name="read-only" value={item.rating_by_user} readOnly />
                                        </div>

                                    </div>
                                    <p className="review-detail">{item.review}</p>
                                </div>
                            ))
                            }
                        </div>)
                        : (<p>No Reviews Found</p>)
                    }
                </div >
            </Box >


        </>
    );
    const featuresDetails = (
        <>
            <Box className='features-details'>
                <div className='features-details-item'>
                    {allFeatures.map((item, index) => (
                        <span key={index} className="data">
                            <img src={item.icon_url} loading="lazy" alt='icon' style={{ opacity: item.flag ? '1' : '0.4' }} />
                            <p style={{ opacity: item.flag ? '1' : '0.4' }}>{item.feature_name}</p>
                        </span>
                    ))
                    }
                </div>

                <div className="features-details-info">
                    <div className="feature-1">
                        <span className="size">
                            <span className="label">Vehicle Type: </span> {data.size}
                        </span>
                        <span className="type">
                            <span className="label">Parking Type: </span> {data.type}
                        </span>
                        <span className="access">
                            <span className="label">Has Access Lock: </span> {data.has_access_lock ? 'Yes' : 'No'}
                        </span>
                    </div>
                    <div className="feature-2">
                        <span className="size">
                            <span className="label">Slot Width: </span> {data.slot_width === "null" ? '-' : data.slot_width}
                            <span>{data.slot_width === "null" ? '' : 'cm'}</span>
                        </span>
                        <span className="size">
                            <span className="label">Slot Height: </span>  {data.slot_height === "null" ? '-' : data.slot_height}
                            <span>{data.slot_height === "null" ? '' : 'cm'}</span>
                        </span>
                        <span className="size">
                            <span className="label">Slot Length: </span>  {data.slot_length === "null" ? '-' : data.slot_length}
                            <span>{data.slot_length === "null" ? '' : 'cm'}</span>
                        </span>
                    </div>
                </div >
            </Box >
        </>
    );
    const slotDetails = (
        <>
            <Box className='slot-details'>
                <div className="booked-slots">
                    <h3>Booked Slots</h3>
                    <div className="bo-wrapper">
                        {data.booked_time_slots && data.booked_time_slots.length !== 0 ? (
                            <div>
                                {data.booked_time_slots.map((item, index) => (
                                    <p key={index} className="slot-time">
                                        <span className="start">{moment(item.start_time).format("lll")}</span> to
                                        < span className="end" >{moment(item.end_time).format("lll")}</span>
                                    </p>
                                ))
                                }
                            </div>
                        ) : (<p> No Parking slots have been booked</p>)
                        }

                    </div>
                </div>
                <div className="available-slots">
                    <h3>Available Slots</h3>
                    <div className="av-wrapper">
                        {data.parking_time_slots && data.parking_time_slots.length !== 0 ? (
                            <div>
                                {data.parking_time_slots.map((item, index) => (
                                    <p key={index} className="slot-time">
                                        <span className="start">{moment(item.start_time).format("lll")}</span> to
                                        <span className="end" >{moment(item.end_time).format("lll")}</span>
                                        <span className="price"><span>€{item.price}</span><sub>/hr</sub> </span>
                                        <button className="primary-btn" onClick={() => handleClickBook(item)}>Book</button>
                                    </p>
                                ))
                                }
                            </div>
                        ) : (<p> No Parking slots are available</p>)
                        }

                    </div>
                </div>
            </Box >


        </>
    );
    const ratingDelete = (
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to delete this parking?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleDelete}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
    const ratingUpdate = (
        <Dialog
            open={popup}
            onClose={handleClose}>
            <DialogTitle sx={{ textAlign: 'center', color: '#bd3f32', fontSize: '1.75rem' }}>
                {"Update Your Reviews"}
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px' }}>

                <Rating
                    value={rating}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                    size="large"
                />
                <TextField
                    id="review"
                    label="Update Your Thoughts"
                    placeholder='Update Reviews'
                    value={reviews}
                    onChange={(e) => {
                        setReviews(e.target.value);
                    }}
                />

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Skip
                </Button>
                <Button onClick={handleUpdate}>Update</Button>
            </DialogActions>
        </Dialog>
    )
    const bookParking = (
        <Dialog sx={{
            "& .MuiDialog-paper": {
                minWidth: '450px'
            }
        }}
            open={bookpopup}
            onClose={handleClose}>
            <BookParking summarydata={bookdata} userId={data.user_id} parkingId={data.id} country={data.country} />
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Skip
                </Button>
            </DialogActions>
        </Dialog>
    )
    const offerParking = (
        <Dialog sx={{
            "& .MuiDialog-paper": {
                minWidth: '450px'
            }
        }}
            open={offerpopup}
            onClose={handleClose}>
            <OfferSlot userId={data.user_id} parkingId={data.id} />
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Skip
                </Button>
            </DialogActions>
        </Dialog>
    )
    const historyButton = (
        <Box className='history'>
            <button className="primary-btn" onClick={() => goToHistory()}> Go back to history page </button >
        </Box>
    )
    const offerButton = (
        <Box className='history'>
            <button className="primary-btn" onClick={() => handleClickOffer()}> Offer Slot </button >
        </Box>
    )
    return (
        <>
            {(localStorage.getItem('fromHistroy') || localStorage.getItem('fromMyParking')) ? '' : (<Box sx={{ paddingBottom: '30px' }}>{Search}</Box>)}
            <Box>{mapDetails}</Box>
            <Box>{parkingDetails}</Box>
            <Box>{featuresDetails}</Box>
            {(!localStorage.getItem('fromHistroy') && !localStorage.getItem('fromMyParking')) ? (<Box>{slotDetails}</Box>) : ''}
            {(localStorage.getItem('fromHistroy') && !localStorage.getItem('fromMyParking')) ? (<Box>{historyButton}</Box>) : ''}
            {(!localStorage.getItem('fromHistroy') && localStorage.getItem('fromMyParking')) ? (<Box>{offerButton}</Box>) : ''}
            <Box>{ratingDelete}</Box>
            <Box>{ratingUpdate}</Box>
            <Box>{bookParking}</Box>
            <Box>{offerParking}</Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
