import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Card, Tabs, Tab, } from "@mui/material";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PlaceIcon from '@mui/icons-material/Place';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import '../../Styles/dashboard.scss'
import { environment } from '../../environment/environment';
import axios from 'axios';
import moment from 'moment/moment';
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DashboardMain() {
    const [registerdParkings, setRegisterdParkings] = useState(0)
    const [offeredParkings, setOfferedParkings] = useState(0)
    const [offerdBookedParkings, setOfferdBookedParkings] = useState(0)
    const [ownedBookedParkings, setOwnedBookedParkings] = useState(0)
    const [upcomingOfferedParkings, setUpcomingOfferedParkings] = useState([])
    const [upcomingBookedParkings, setUpcomingBookedParkings] = useState([])
    const [bookedYearly, setBookedYearly] = useState([])
    const [value, setValue] = useState(0);
    const [chartvalue, setChartValue] = useState(0);
    const navigate = useNavigate();

    const cardItems = [
        {
            title: 'Registered Parkings',
            count: registerdParkings,
            icon: <AppRegistrationIcon sx={{ color: '#0099ff', fontSize: '4rem' }} />
        },
        {
            title: 'Offered Parkings',
            count: offeredParkings,
            icon: <DirectionsCarFilledIcon sx={{ color: '#0099ff', fontSize: '4rem' }} />
        },
        {
            title: 'Offered Booked Parkings',
            count: offerdBookedParkings,
            icon: <LocalParkingIcon sx={{ color: '#0099ff', fontSize: '4rem' }} />
        },
        {
            title: 'Booked Parkings',
            count: ownedBookedParkings,
            icon: <CreditScoreIcon sx={{ color: '#0099ff', fontSize: '4rem' }} />
        }
    ]
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr',
            'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [bookedchartData, setBookedChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr',
            'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });

    function UpcomingTabPanel(props) {
        const { children, value, index } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function ChartTabPanel(props) {
        const { children, value, index } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    const handleChartChange = (event, newValue) => {
        setChartValue(newValue);
    };

    useEffect(() => {
        GetCounts()
    }, []);

    const GetCounts = async (e) => {
        const API_URL = environment.apiBaseUrl
        const token = localStorage.getItem('token')
        const header = {
            "Authorization": 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        try {
            let requestBody = {
                userId: parseFloat(localStorage.getItem('userId'))
            }
            let chartRequest = {
                userId: parseFloat(localStorage.getItem('userId')),
                year: (new Date()).getFullYear()
            }
            await axios.post(API_URL + 'parkingCount', requestBody, { headers: header }).then((response) => {
                if (response.data.statusCode === 200) {
                    setRegisterdParkings(response.data.parkingCountResult.userRegisterdParkings)
                    setOfferedParkings(response.data.parkingCountResult.userOfferedParkings)
                    setOfferdBookedParkings(response.data.parkingCountResult.userOfferdBookedParkings)
                    setOwnedBookedParkings(response.data.parkingCountResult.userOwnedBookedParkings)
                } else { toast.error(response.data.message) }
            })
            await axios.post(API_URL + 'futureOfferdParkings', requestBody, { headers: header }).then((res) => {
                if (res.data.statusCode === 200) {
                    setUpcomingOfferedParkings(res.data.response)
                    setUpcomingBookedParkings(res.data.upcomingBookedParkings)
                } else { toast.error(res.data.message) }
            })
            await axios.post(API_URL + 'monthlyBooking', chartRequest, { headers: header }).then((res) => {
                if (res.data.statusCode === 200) {
                    setBookedYearly(res.data.countArray)
                    setBookedChartData({
                        labels: ['Jan', 'Feb', 'Mar', 'Apr',
                            'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        datasets: [
                            {
                                label: "Booked",
                                data: res.data.countArray,
                                backgroundColor: '#0099ff'
                            }
                        ]
                    })
                    setChartData({
                        labels: ['Jan', 'Feb', 'Mar', 'Apr',
                            'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        datasets: [
                            {
                                label: "Registered",
                                data: ['42', '52', '56', '27', '17',
                                    '35', '58', '54', '20', '25', '10', '40'],
                                backgroundColor: '#bd3f32'
                            },
                            {
                                label: "Booked",
                                // data: ['40', '50', '52', '20', '12',
                                //     '32', '53', '45', '12', '20', '8', '34'],
                                data: res.data.countArray,
                                backgroundColor: '#0099ff'
                            }
                        ]
                    })
                } else {toast.error(res.data.message) }
            })
        } catch (error) {
            if (error.response?.data?.statusCode === 498) {
                navigate('/login')
            }
        }
    }
    const parkingCount = (
        <Box sx={{ display: 'flex', gap: '1rem', padding: '0 1rem' }}>
            {cardItems.map((item, index) => (
                <Card key={index} className="dashboard-count">
                    <p className='dashboard-count-title'>{item.title}</p>
                    <div className="dashboard-count-info">
                        {item.icon}
                        <span className="dashboard-count-info-total">{item.count}</span>
                    </div>
                </Card>
            ))}

        </Box>
    );
    const upcomingOffered = (
        <Box>
            {upcomingOfferedParkings && upcomingOfferedParkings.length !== 0 ?
                (<>
                    {upcomingOfferedParkings.map((item, index) => (
                        <div key={index} className="upcoming-wrapper">
                            <ArrowCircleRightIcon />
                            <span className="address">
                                <span className="name">{item.parkingName}</span>
                                <span className="location"><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}</span>
                            </span>
                            <span className="time"><span className="label">Start Time: </span>{moment(item.start_time).format("lll")}</span> to
                            <span className="time"><span className="label">End Time: </span>{moment(item.end_time).format("lll")}</span>
                        </div>

                    ))
                    }
                </>)
                : (
                    <div className="no-parking-found">
                        <p>No parking found</p>
                    </div>
                )}
        </Box >
    )
    const upcomingBooked = (
        <Box>
            {upcomingBookedParkings && upcomingBookedParkings.length !== 0 ?
                (<>
                    {upcomingBookedParkings.map((item, index) => (
                        <div key={index} className="upcoming-wrapper">
                            <ArrowCircleRightIcon />
                            <span className="address">
                                <span className="name">{item.parkingName}</span>
                                <span className="location"><PlaceIcon sx={{ fontSize: 'small' }} />{item.city}</span>
                            </span>
                            <span className="time"><span className="label">Start Time: </span>{moment(item.bookingStartTime).format("lll")}</span> to
                            <span className="time"><span className="label">End Time: </span>{moment(item.bookingEndTime).format("lll")}</span>
                        </div>

                    ))
                    }
                </>)
                : (
                    <div className="no-parking-found">
                        <p>No parking found</p>
                    </div>
                )}
        </Box >
    )
    const upcomings = (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Upcoming Offered Parkings" className='tab' />
                    <Tab label="Upcoming Booked Parkings" className='tab' />
                </Tabs>
            </Box>
            <UpcomingTabPanel value={value} index={0}>
                {upcomingOffered}
            </UpcomingTabPanel>
            <UpcomingTabPanel value={value} index={1}>
                {upcomingBooked}
            </UpcomingTabPanel>
        </Box>
    );
    const charts = (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={chartvalue} onChange={handleChartChange}>
                    <Tab label="Parking Analysis" className='tab' />
                    <Tab label="Booked Parking Analysis" className='tab' />
                </Tabs>
            </Box>
            <ChartTabPanel value={chartvalue} index={0}>
                <Bar data={chartData} />
            </ChartTabPanel>
            <ChartTabPanel value={chartvalue} index={1}>
                <Bar data={bookedchartData} />
            </ChartTabPanel>
        </Box>

    );
    return (
        <>
            <Box sx={{ width: '100%' }}>
                {parkingCount}
                <Box sx={{ margin: '1rem' }} className='upcoming'>
                    {upcomings}
                </Box>
                <Box sx={{ margin: '1rem' }} className='charts'>
                    {charts}
                </Box>
            </Box>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
