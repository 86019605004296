import React, { useState, useEffect } from 'react';
import Geocode from "react-geocode";
import { environment } from '../environment/environment';

export default function CurrentAddress() {
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [address, setAddress] = useState('');
    const [status, setStatus] = useState(null);
    Geocode.setApiKey(environment.googleAPI);

    useEffect(() => {
        { getLocation() }
    });

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setStatus(null);
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    (response) => {
                        setAddress(response.results[0].formatted_address)
                    },
                    // (error) => {
                    //     console.error(error);
                    // }
                );
                localStorage.setItem('currentLat', lat)
                localStorage.setItem('currentLng', lng)
                localStorage.setItem('currentAddress', address)
                // console.log(lat, lng, address)
            }, () => {
                setStatus('Unable to retrieve your location');
                alert(status)
            });
        }
    }
    return (null);
}
